    .deb-container {
        margin-top: 182px;
        margin-bottom: 45px;
        /* padding: 0px 0px 0px 0px !important; */
        /* margin-left: -5px !important; */
    }
    
    .debit-card-img {
        width: 625px;
        height: auto;
    }

    .debit-card-img-Mob{
        display: none;
    }

    .card-title-holder{
        margin: 55px 0px 55px 40px;
    }
    
    .debit-card-container {
        /* background-color: #D1964A; */
        background: linear-gradient(90deg, #F4EEE2 0%, #F6D5AC 100%);;
        border-radius: 20px;
        /* padding: 40px; */
        margin-left: -20px;
        overflow: hidden;
    }
    
    .card-title {
        color: #212426;
        font-weight: 700;
        font-size: 48px;
    }
    
    .card-sub-title {
        color: #343739;
        font-size: 18px;
        margin-top: 30px;
        font-weight: 500;
    }
    
    .debit-card-input-phone-number {
        border-bottom: 1px solid #FFFFFF;
    }
    
    .debit-card-phone-num-input {
        background-color: #D1964A;
        border: none;
        outline: none;
        color: #FFFFFF;
    }
    
    .debit-card-send-button {
        background-color: #FFFFFF;
        border: 2px solid rgba(255, 255, 255, 0.05);
        padding: 6px 20px;
        border-radius: 40px;
        box-shadow: -8px -8px 12px rgba(255, 255, 255, 0.04), 8px 8px 12px rgba(0, 0, 0, 0.16);
        color: #000000;
        font-size: 16px;
        font-weight: bold;
    }

    .carddownloadLinks{
        margin-top: 60px;
        display: flex;
    }

    @media screen and (min-width: 320px) and (max-width: 768px) {
        .deb-container {
            margin-top: 80px;
            padding: 0px;
            margin-left: 0px !important;
        }

        .card-title-holder{
            margin-top: -80px;
        }

        .debit-card-img{
            display: block;
        }

        .debit-card-img-Mob{
            display: block;
            width: 100%;
            height: auto;
        }

        .card-title {
            font-weight: 700;
            font-size: 36px;
        }
        .card-sub-title {
            font-size: 18px;
            font-weight: 500;
            margin-top: 15px;
        }
        .debit-card-img {
            display: none;
        }
        .debit-card-container {
            /* padding: 35px 25px !important; */
            /* margin-right: 20px !important; */
            display: flex;
            justify-content: center;
            align-content: center;
        }
        .carddownloadLinks{
            margin-top: 30px;
            display: inline-block;
        }
        .cardGooglePlayLogo{
            width: 149px !important;
            height: auto;
            margin-top: 15px;
        }
    }
    
    @media screen and (min-width: 767px) and (max-width: 1024px) {
        .debit-card-img {
            display: none;
        }
        .debit-card-img-Mob{
            display: none;
        }
        .card-title-holder{
            margin-top: 0px;
            padding-top: 30px;
        }
        .debit-card-container{
            margin-top: 195px;
        }
    }


    @media screen and (min-width: 1024px) and (max-width: 1400px) {
        .debit-card-img {
            width: 460px;
            height: auto;
        }
        .debit-card-img-Mob{
            display: none;
        }
    }