.recapContainer{
   text-align: center;
   margin-bottom: 80px;
}

.headerSection {
    background-image: url(../../images/YearRecapImg/headerbgImage.png);
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 70px;
}

.logoContainer {
    width: 125px;
    height: auto;
}

.titleImg {
    max-width: 40%;
    height: auto;
}

.bodyContainer {
    padding: 0px 200px 0px 200px;
}

.headderSubtitle{
    padding: 0px 200px 0px 200px;
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 500;
}

.bodyTitle {
    font-weight: 600;
    font-size: 32px;
    color: #FFFFFF;
    margin-top: 50px;
}

.bodySubtitle {
    font-weight: 500;
    font-size: 24px;
    color: #FFFFFF;
    margin-top: 30px;
}

.backgroundBgOne {
    width: 50%;
    height: auto;
    margin-top: -40px;
}

.rewardsectionTitle {
    background: linear-gradient(90deg, #F4EEE2 0%, #F6D5AC 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    position: absolute;
    color: #FFFFFF;
    /*  top: 0px;
    left: 25%; */
    font-weight: 700;
    font-size: 32px;
    line-height: 118.02%;
    top: 50px;
}

.reward {
    font-weight: 600;
    font-size: 36px;
    line-height: 118.02%;
    color: #FFFFFF;
    position: absolute;
    top: 0px;
    margin-top: 310px;
    left: 45%;
}

.rewardsectionTitleTwo {
    background: linear-gradient(90deg, #F4EEE2 0%, #F6D5AC 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    position: absolute;
    color: #FFFFFF;
    /*  top: 0px;
    left: 10%; */
    font-weight: 500;
    font-size: 32px;
    line-height: 118.02%;
    /* top: 440px; */
    bottom: 0px;
}

.RewardImg {
    width: 150px;
    height: auto;
    position: absolute;
    top: 0px;
    left: 43%;
    margin-top: 165px;
}

.cardContainer {
    border-radius: 20px;
    /* box-shadow: -8px -8px 12px rgba(255, 255, 255, 0.04), 8px 8px 12px rgba(0, 0, 0, 0.16); */
    /* width: fit-content; */
    width: 750px;
    padding: 30px;
    margin-top: 40px;
    position: relative;
    z-index: 9;
}

.cardContainerHolder {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    width: 750px;
}

.cardTitle {
    color: #0C0C0C;
    font-weight: 700;
    font-size: 48px;
}

.cardSubtitle {
    color: #6B39B2;
    font-weight: 700;
    font-size: 64px;
}

.cardSubtiltleTwo {
    color: #0C0C0C;
    font-weight: 700;
    font-size: 32px;
    /* padding-bottom: 30px; */
}

.satsCoin {
    width: 132px;
    height: auto;
}

.cardFooterTitle {
    font-size: 24px;
    color: #787878;
    font-weight: 500;
    text-align: left;

}

.cardFooter {
    background-color: #E6CAA6;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px -30px -30px -30px;
    border-radius: 0px 0px 20px 20px;
}

.satsCoin {
    width: auto;
    height: 60px;
}

.spinWheel {
    width: 250px;
    height: auto;
    position: absolute;
    left: 38%;
    top: 20%
}

.spinTitle {
    font-weight: 600;
    font-size: 36px;
    line-height: 118.02%;
    color: #FFFFFF;
    position: absolute;
    top: 0px;
    margin-top: 360px;
    left: 42%;
}

.spinRangeBarContainer {
    display: flex;
    justify-content: center;
    border-radius: 20px 20px 20px 20px;
    overflow: hidden;
    margin: 40px 0px 40px 0px;
}

.spinRange {
    background: #F5B668;
    color: #101317;
    font-style: italic;
    padding: 20px;
    width: 65%;
    font-weight: 700;
    font-size: 32px;
    text-align: left;
}

.flatRange {
    background: #956734;
    color: #FFFFFF;
    font-style: italic;
    padding: 20px;
    width: 35%;
    font-weight: 700;
    font-size: 32px;
    text-align: right;
}

.ShoppingTrends {
    max-width: 70%;
    height: auto;
    margin-top: -100px;
    margin-bottom: -90px;
}

.SpinWheelImg{
    max-width: 70%;
    height: auto;
    margin-top: -100px;
    margin-bottom: -90px;
}


.collectivelyContainer {
    display: flex;
    justify-content: center;
    margin-top: -80px;
}

.iconsContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.cardImages {
    max-width: 40%;
    height: auto;
}

.iconNames {
    color: #0C0C0C;
    font-weight: 700;
    font-size: 24px;
}

.swiggyBg {
    max-width: 100%;
    height: auto;
}

.swiggyCardContainer {
    border-radius: 20px;
    width: 750px;
    /* padding: 30px; */
    margin-top: 40px;
    z-index: 9;
}

.swiggyLogo {
    max-width: 40%;
    height: auto;
}

.BitFriday {
    border-radius: 20px;
    width: 750px;
    padding: 30px;
    margin-top: 40px;
    z-index: 9;
}

.BitFridayImg {
    max-width: 80%;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.lastCard {
    border-radius: 20px;
    width: 750px;
    padding: 30px;
    margin-top: 40px;
    z-index: 9;
}

.yearRecapfooterSection {
    background-image: url(../../images/YearRecapImg/headerbgImage.png);
    width: 100%;
    height: 380px;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 70px;
    margin-top: -80px;
}

.footerTitle {
    max-width: 40%;
    height: auto;
    margin-top: 90px;
}

.atmCard{
    max-width: 35%;
    height: auto;
    margin-top: 125px;
    margin-bottom: 50px;
}

@media screen and (max-width: 479px) {
    .container {
        padding: 10px;
    }

    .headerSection {
        background-image: url(../../images/YearRecapImg/headerbgImage.png);
        width: 100%;
        height: auto;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 20px;
    }

    .headderSubtitle{
        padding: 0px 20px 0px 20px;
        color: #FFFFFF;
        font-weight: 400;
        font-size: 18px;
    }

    .titleImg {
        max-width: 100%;
        height: auto;
    }

    .bodyContainer {
        padding: 20px;
        overflow: hidden;
    }

    .logoContainer {
        width: 30%;
        height: auto;
    }

    .bodyTitle {
        font-weight: 600;
        font-size: 25px;
        color: #FFFFFF;
        margin-top: 20px;
    }

    .bodySubtitle {
        font-weight: 400;
        font-size: 18px;
        color: #FFFFFF;
        margin-top: 20px;
    }

    .backgroundBgOne {
        width: 90%;
        height: auto;
        margin-top: -30px;
    }

    .ShoppingTrends {
        min-width: 319px;
        margin-top: -75px;
        margin-bottom: 0px;
    }

    .SpinWheelImg{
        max-width: 230px;
        height: auto;
        
            margin-top: 10px;
            margin-bottom: 0px;
    }

    .atmCard{
        min-width: 230px;
        height: auto;
        
            margin-top: 100px;
            margin-bottom: 0px;
        
    }

    .rewardsectionTitle {
        background: linear-gradient(90deg, #F4EEE2 0%, #F6D5AC 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        position: absolute;
        color: #FFFFFF;
        top: 40px;
        /* left: 6%; */
        font-weight: 700;
        font-size: 18px;
        line-height: 118.02%;
    }

    .RewardImg {
        width: 70px;
        height: auto;
        position: absolute;
        top: 0px;
        left: 40%;
        margin-top: 90px;
    }

    .reward {
        font-weight: 600;
        font-size: 22px;
        line-height: 118.02%;
        color: #FFFFFF;
        position: absolute;
        top: 0px;
        margin-top: 160px;
        left: 38%;
    }

    .rewardsectionTitleTwo {
        background: linear-gradient(90deg, #F4EEE2 0%, #F6D5AC 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        position: absolute;
        color: #FFFFFF;
        bottom: 40px;
        /* left: 5%; */
        font-weight: 500;
        font-size: 18px;
        line-height: 118.02%;
        /* margin-top: 160px; */
    }

    .cardContainerHolder {
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        width: 300px;
        margin-top: -60px;
    }

    .spinwheelimgContainer {
        margin-top: -40px;
    }

    .cardContainer {
        border-radius: 20px;
        /* box-shadow: -8px -8px 12px rgba(255, 255, 255, 0.04), 8px 8px 12px rgba(0, 0, 0, 0.16); */
        /* width: fit-content; */
        width: 350px;
        padding: 20px;
        margin-top: 40px;
    }

    .cardTitle {
        color: #0C0C0C;
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 10px;
    }

    .cardSubtitle {
        color: #6B39B2;
        font-weight: 700;
        font-size: 32px;
    }

    .cardSubtiltleTwo {
        color: #0C0C0C;
        font-weight: 700;
        font-size: 18px;
        /* padding-bottom: 30px; */
        margin-top: 10px;
    }

    .satsCoin {
        width: 30px;
        height: auto;
    }

    .collectivelyContainer {
        display: flex;
        justify-content: center;
        margin-top: -80px;
    }

    .cardFooterTitle {
        font-size: 18px;
        color: #787878;
        font-weight: 500;
        text-align: left;

    }

    .cardFooter {
        background-color: #E6CAA6;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px -20px -20px -20px;
        border-radius: 0px 0px 20px 20px;
        /* padding: 20px; */
    }

    .spinWheel {
        width: 140px;
        height: auto;
        position: absolute;
        left: 31%;
        top: 21%
    }

    .spinRange {
        font-weight: 700;
        font-size: 18px;
    }

    .flatRange {
        font-weight: 700;
        font-size: 17px;
    }

    .iconNames {
        font-weight: 700;
        font-size: 18px;
    }

    .BitFridayImg {
        max-width: 100%;
        height: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .cardimages {
        max-width: 70%;
        height: auto;
    }

    .spinTitle {
        font-weight: 600;
        font-size: 22px;
        line-height: 118.02%;
        color: #FFFFFF;
        position: absolute;
        top: 0px;
        margin-top: 220px;
        left: 38%;
    }

    .footerTitle {
        max-width: 100%;
        height: auto;
        margin-top: 90px;
    }

    .yearRecapfooterSection {
        background-image: url(../../images/YearRecapImg/headerbgImage.png);
        width: 100%;
        height: 250px;
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 20px;
        margin-top: -80px;
    }

}