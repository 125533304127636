.teamImgContainer {
    position: relative;
    overflow: hidden;
    border: 3px solid transparent;
    /* width: 85%; */
    width: 367px;
    height: 380px;
    border-radius: 14px;
    background: linear-gradient(to right, #212426, #212426), linear-gradient(to left, #171717, #343434);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
}

.teamImg {
    width: 100%;
}

.emp {
    text-align: center;
    align-content: space-between;
    align-items: center;
    position: relative;
    margin-top: 105px;
}

.empDetails {
    background-color: #2A2E31;
    border-radius: 14px;
    text-align: left;
    width: 320px;
    height: auto;
    padding: 15px;
    position: absolute;
    bottom: -60px;
}

.team-footer-Social-media {
    border: 1.5px solid #A6A7A8;
    border-radius: 100%;
    padding: 5px 8px;
}

.team-socialmedia-icon {
    margin-bottom: 2px;
}

.empProfile {
    margin-top: 14px;
}

.icon {
    margin-right: 15px;
}

.card {
    box-shadow: 0 4px 8px 0 hwb(0 0% 100% / 0.2);
    background-color: #f1f1f1;
}

.empName {
    color: #FFF4E2;
    font-weight: 700;
}

.empWrk {
    color: #A6A7A8;
    font-weight: 600;
    margin-bottom: 0px;
}

@media screen and (min-width:320px) and (max-width:940px) {
    .team-footer-Social-media {
        border: 1.5px solid #A6A7A8;
        border-radius: 100%;
        padding: 7px 10px;
    }
    .koo-icon {
        margin-top: 30px;
    }
   
    /* .emp {
        margin-top: 26px;
    } */
}