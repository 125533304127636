  .favBrandsListContainer {
      margin: 0px;
      padding: 0px !important;
      text-align: center;
      background: #0F0F0F;
  }

  .favBrandsListTitle {
      color: var(--text-high-emphasis, #FFF);
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      margin-top: 250px;
  }

  .favBrandsListSubTitle {
      color: var(--text-high-emphasis, #FFF);
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      margin-top: 30px;
  }

  .favBrandsListSubTitleLinear {
      background: linear-gradient(90deg, #FBCC65 0%, #FBCC65 0.01%, #FBCC65 26.04%, #E59212 80.4%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  }

  .brandImages {
      width: 150px;
      height: auto;
      margin: 20px 0px 25px 0px;
  }

  .brandStrokeContainer{
    margin: 0px 15px;
  }

  .brandImagesContainer {
      /* margin-top: 30px; */
      overflow: scroll;
      white-space: nowrap;
  }

  .brandImagesContainer .innerContainer {
      display: inline-block;
      animation: scrollBounce 5s infinite;
  }

  .primemembershipTitle {
      color: var(--text-high-emphasis, #FFF);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.32px;
      background-color: #0F0F0F;
  }

  .brandRightLayout {
      background: linear-gradient(270deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
      height: 407px;
      width: 320px !important;
      position: absolute;
      margin-top: 46px;
      z-index: 999;
      right: 0px !important;
  }

  .brandLeftLayout {
      background: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
      height: 407px;
      width: 320px !important;
      position: absolute;
      margin-top: 46px;
      z-index: 999;
      left: 0px;
  }

  .rangeBarContainer {
      padding: 30px 15px 50px 15px;
      background-color: #0F0F0F;
  }

  .rangeBarHeader {
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 46px;
      letter-spacing: -0.6px;
      width: 271px;
  }

  .rangeBarTitle {
      color: var(--text-high-emphasis, #FFF);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.32px;
  }

  .value {
      color: var(--text-high-emphasis, #FFF);
      text-align: center;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 46px;
      letter-spacing: -0.6px;
  }

  .btcImgContainer {
      gap: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin-top: -20px;
  }

  .btcImg {
      width: 16px;
      height: auto;
  }

  .brandSubContainerOne {
      display: flex;
      margin-top: 46px;
      gap: 22px;
      margin-left: 20px;
  }


  .brandStroke {
      /* opacity: 0.2; */
      background: rgba(255, 124, 23, 0.40);
      /* width: 101%; */
      height: 1px;
      /* margin-top: 40px; */
  }

  .brandImgContainer {
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .brandImageContainerRowOne {
      width: 180px;
      height: 180px;
      flex-shrink: 0;
      border-radius: 16.8px;
      background: #0F0F0F;
      /* display: grid; */
      /* text-align: center !important; */
      /* justify-content: center; */
      border: 1.17px solid rgba(255, 255, 255, 0.60);
  }

  .brandImageContainerRowTwo {
    width: 180px;
    height: 180px;
      flex-shrink: 0;
      border-radius: 16.8px;
      background: #0F0F0F;
      display: grid;
      text-align: center !important;
      justify-content: center;
      border: 1.17px solid rgba(255, 255, 255, 0.60);
  }

  .brandContainer {
      margin-top: 130px;
  }

  .worthPrice {
      color: var(--text-high-emphasis, #FFF);
      text-align: center;
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      margin-top: 14px;
      /* margin-top: 14px; */
      /* margin-bottom: 17px; */
  }

  .instantRewardContainer {
      margin-top: 250px;
      display: flex !important;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 137px;
  }

  .instantReward {
      color: var(--text-high-emphasis, #FFF);
      text-align: left;
      font-size: 55.699px;
      font-style: normal;
      font-weight: 700;
      line-height: 71.613px;
  }

  .instantRewardLinear {
      background: linear-gradient(90deg, #FBCC65 0%, #FBCC65 0.01%, #FBCC65 26.04%, #E59212 80.4%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  }

  .instantImg {
      width: 355px;
      height: auto;
  }

  @media screen and (min-width: 300px) and (max-width: 940px) {

    .brandRightLayout {
        background: linear-gradient(270deg, #000 -40%, rgba(0, 0, 0, 0.00) 100%);
        width: 100px !important;
        height: 406px;
    }
  
    .brandLeftLayout {
        background: linear-gradient(90deg, #000 -40%, rgba(0, 0, 0, 0.00) 100%);
        width: 100px !important;
        height: 406px;
    }

      .favBrandsListTitle {
          font-size: 28px;
          margin: 100px 80px 0px 80px;
      }

      .favBrandsListSubTitle {
          font-size: 18px;
          margin: 15px 20px 0px 20px;
      }

      .brandContainer {
          margin-top: 0px;
      }

      .instantReward {
          font-size: 28px;
          line-height: 36px;
          text-align: center;
      }

      .instantImg {
          width: 243px;
          height: auto;
      }

      .instantRewardContainer {
          margin-top: 100px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          gap: 50px;
      }
  }

  

  

  