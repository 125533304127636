.modalContainer {
    border-radius: 20px;
    background: #0f0f0f;
    margin: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #646667;
}

.modalTitle {
    color: var(--text-high-emphasis, #FFF);
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: 46px;
    letter-spacing: -0.76px;
    text-align: center !important;
}

.modalSubtitle {
    color: var(--text-medium-emphasis, #A6A7A8) !important;
    text-align: center;
    font-size: 18px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: 28px;
    margin: 15px 0px 45px 0px !important;
}

.inputFieldContainer {
    border-radius: 10px;
    border: 1px solid var(--text-disabled, #343739);
    background: #0F0F0F;
    /* width: fit-content; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px 10px 10px 20px;
    margin-bottom: 10px !important;
}

.inputField {
    border: 1px solid var(--text-disabled, #343739);
    background: #0F0F0F;
    border: none;
    outline: none;
    color: #FFF;
}

.inputFieldDivider {
    width: 1px;
    height: 40px;
    opacity: 0.2;
    background: #FFF;
    margin: 4px 20px 4px 15px;
}

.getLinkButton {
    border-radius: 10px;
    background: var(--text-disabled, #343739);
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
    color: var(--text-medium-emphasis, #A6A7A8);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.orContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    gap: 30px;
}

.orDivider {
    opacity: 0.4;
    background: #646667;
    width: 150px;
    height: 1px;
}

.orContainer {
    color: var(--text-medium-emphasis, #A6A7A8);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.ModalQRConatiner {
    margin: 50px 0px;
}

.ModalQRTitle {
    color: var(--text-high-emphasis, #FFF);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 15px;
}

.modalQRImg {
    width: 200px;
    height: 200px;
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--text-high-emphasis, #FFF);
    overflow: hidden;
}

.modalDownloadButtonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7.5px;
    margin-bottom: 0px;
}

.modalPlaystore {
    width: 135px;
    height: auto;
}

.modalAppstore {
    width: 135px;
    height: auto;
}

.modalRespMessage {
    color: var(--brand-primary, #D1964A);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
}

.modalTimerConatiner{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px !important;
}

.modalTimer {
    color: var(--text-medium-emphasis, #A6A7A8);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalTimerImg{
    width: 24px;
    height: auto;
}

.moadalClosBbutton{
    position: absolute;
    right: 30px;
    top: 30px;
    border: none;
    background: transparent;
}

.modalCloseButtonImg{
    width: 32px;
    height: 32px;
}