.cardScreen-card {
    text-align: end;
    margin-right: -40px;
}

.head-title-card {
    background: linear-gradient(90deg, #FBCC65 0%, #FBCC65 0.01%, #FBCC65 26.04%, #E59212 80.4%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 64px;
    font-weight: 700;
    line-height: 120%;
    /* margin-top: 100px; */
}

.card-homeScreen-title-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    /* gap: 60px; */
    margin-top: 250px;
    align-items: center;
}

.cardScreenMain {
    /* background-color: #2A2E31; */
}

/* 
.cardScreen {
    background: url('../../images/dotsBg.png') no-repeat;
    background-size: cover;
    width: 100%;
    display: flex;
    justify-content: center;
} */

.cardScreen-sub-title {
    color: #FFFFFF;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    margin-top: 30px;
}

.cardScreenSectionOne {
    align-items: center;
    position: relative;
}

.card-homeScreen-img {
    max-width: 620px;
    max-height: auto;
}


.sectionTwoTitle {
    color: #FFFFFF;
    font-size: 48px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 100px;
}

.sectionTwoContainer {
    margin-top: 80px;
}

.checkImg{
    width: 30px;
    height: auto;
}

.sectionTwoCards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    z-index: 999;
    position: relative;
    /* margin-top: 60px; */
}

.cards {
    background: #212426;
    box-shadow: -8px -8px 12px rgba(255, 255, 255, 0.04), 8px 8px 12px rgba(0, 0, 0, 0.16);
    border-radius: 20px;
    padding: 50px 40px 40px 40px;
    width: 380px;
    height: auto;
    margin-top: 60px;
}

.cardHeader {
    font-weight: 600;
    font-size: 42px;
    color: #FFE099;
    margin-top: 40px;
}

.gosatsCardSubTitle {
    font-weight: 400;
    font-size: 24px;
    color: #FFFFFF;
    margin-top: 20px;
}

.cardRewardImg {
    width: 150px;
    height: auto;
}

.cardHomebg {
    background-color: #ACACAC;
    display: flex;
    justify-content: center;
    text-align: center;
    border-radius: 100%;
    margin-top: 100px;
    margin-left: 30%;
    opacity: 0.1;
    width: 400px;
    height: 366px;
    filter: blur(100px);
    position: absolute;
}

.chooseCardContainer {
    margin-top: 258px;
    text-align: center;
    padding-bottom: 100px;
    /* display: grid;
    justify-content: center; */
}

.chooseCardOneContainer {
    text-align: center !important;
    display: flex;
    /* overflow: hidden !important; */
    justify-content: center;
    gap: 5px;
}

.chooseCard-sub-title {
    font-weight: 500;
    font-size: 18px;
    color: #A6A7A8;
    margin-top: 10px;
    margin-bottom: 60px;
}

.chooseCardOne {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    text-align: center;
    background: linear-gradient(180deg, #1A1917 0%, #2D2212 100%);
    /* border: 1px solid#C9A67A; */
    padding: 34px;
    width: fit-content;
    position: relative;
    width: 250px;
    height: auto;
    /* margin-right: 60px; */
}

.cardImg {
    width: 332px;
    height: 207px;
}

.chooseCardTitle {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    /* margin-top: 25px; */
}

.chooseCardTitleOne{
    color: #FFFFFF;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    margin-top: 110px;
    margin-bottom: 45px;
}

.cardPrice {
    font-weight: 700;
    font-size: 42px;
    color: #FFE099;
    margin-bottom: 50px;
    margin-top: 40px;
}

.perTitle {
    font-weight: 600;
    font-size: 10px;
    color: #A6A7A8;
    margin-bottom: -20px;
}

.cardPriceContainer {
    /* text-align: left; */
}

.gstTitle{
    color: #646667;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 23px;
    margin-top: -48px;
}

.cardDivider {
    border: 1px solid #FFFFFF;
    /* width: 200px; */
    opacity: 0.1;
    margin-top: 0px;
    margin-bottom: 30px;
}

.checkPoints {
    text-align: center !important;
    display: flex;
    align-items: center;
    /* margin-top: 12px; */
}

.checkPointsTitle {
    text-align: center !important;
    display: flex;
    align-items: center;
}

.checkPointsTwo{
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkTitle {
    color: #A6A7A8;
    font-size: 18px;
    font-weight: 600;
    /* margin-bottom: 40px !important; */
}

.recommendedCard {
    background: #443B2D;
    border-radius: 3.97248px;
    padding: 3.97248px 5.95872px;
    width: fit-content;
    position: absolute;
    margin-top: -35px;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #FFE099;
    font-weight: 600;
    font-size: 15.8899px;
    border: 1px solid #C9A67A;
    display: block;
}

.recommendedIcon{
    display: none;
}

/* .cardDetailsContainer {
    display: flex;
    justify-content: center;
    margin-top: 80px;
} */

.checkPoint {
    width: 20px;
    height: auto;
}

.card-faq-subsection {
    padding-bottom: 100px;
    padding-top: 140px;
}

.cardScreen-card-mob {
    display: none;
}

.cardDownloadTitle {
    color: #FFFFFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    text-align: left;
}

.cardDownloadContainer {
    background-color: #212426;
    padding: 16px 16px 16px 32px;
    border-radius: 50px;
    margin-top: 50px;
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
    gap: 20px;
    align-self: center;
}


.cardDownloadButtonTitle {
    display: block !important;
    color: var(--text-high-emphasis, #FFF);
    padding: 18.5px 64px;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    border: none;
    border-radius: 40px;
    background: var(--brand-primary, #D1964A);
    box-shadow: 8px 8px 12px 0px rgba(0, 0, 0, 0.16), -8px -8px 12px 0px rgba(255, 255, 255, 0.04);
}

.cardDownloadButtonTitleMob{
    display: none !important;
    width: 150px;
    color: var(--text-high-emphasis, #FFF);
    padding: 10px 42px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    border: none;
    border-radius: 40px;
    background: var(--brand-primary, #D1964A);
    box-shadow: 8px 8px 12px 0px rgba(0, 0, 0, 0.16), -8px -8px 12px 0px rgba(255, 255, 255, 0.04);
}

.popup-overlay {
    background: rgba(15, 15, 15, 0.5); 
    backdrop-filter: blur(5px); 
    z-index: 1999 !important; 
    position: fixed; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chooseCardOneFeatureTitle{
    padding-right: 20px;
}

[data-popup='tooltip'].popup-overlay {
    background: transparent;
    backdrop-filter: none; 
}

@media screen and (min-width: 320px) and (max-width: 940px) {
    .cardScreen-card {
        display: none;
    }

    .cardScreen-card-mob {
        display: block;

    }

    .card-homeScreen-img {
        width: 295px;
        height: auto;
    }

    .head-title-card {
        font-size: 32px;
    }

    .cardHeader {
        font-size: 36px;
    }

    .gosatsCardSubTitle {
        font-size: 18px;
    }

    .cardScreenMain {
        padding-bottom: 0px;
    }

    .chooseCardOneContainer {
        gap: 5px;
        justify-content: space-between;
    }

    .cardScreen-sub-title {
        margin-top: 25px;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 50px;
    }

    .card-homeScreen-title-container {
        margin-top: 120px;
        text-align: center;
    }

    .chooseCard-sub-title {
        margin-bottom: 40px;
    }

    .downloadLinks {
        margin-top: 100px !important;
    }

    .sectionTwoTitle {
        color: #FFFFFF;
        font-size: 36px !important;
        font-weight: 700;
        margin-bottom: 50px;
    }

    .checkImg{
        width: 20px;
        height: auto;
    }

    .checkPointsTitle {
        text-align: center !important;
        display: flex;
        align-items: center;
        margin-bottom: 45px;
    }

    .sectionTwoCards {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        /* margin-top: 60px; */
    }

    .gstTitle{
        font-size: 12px;
        margin-top: -28px;
        margin-bottom: 10px;
    }

    .cards {
        margin-top: 40px;
    }

    .sectionTwoContainer {
        margin-top: 100px;
        z-index: 9999;
    }

    .cardHomebg {
        display: none;
    }

    .cardDownloadButtonTitle {
        display: none !important;
        
    }

    .chooseCardTitleOne{
        margin-bottom: 138px;
        margin-top: 0px;
    }

    .cardDownloadContainer {
        padding: 16px 16px 16px 24px;
    }

    .cardDownloadButtonTitleMob{
        display: block !important;
    }

    .cardDownloadTitle{
        font-size: 14px;
        text-align: left;
    }

    /* .cardDetailsContainer {
        display: flex;
        justify-content: space-between;
        padding-top: 25px;
        margin-top: 40px;
    } */

    .cardDetailsContainer::-webkit-scrollbar {
        display: none;
    }

    .cardDetailsContainer {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .chooseCardOneFeatureTitle{
        width: 130px;
        padding-right: 0px;
    }
    .cardDivider {
        /* width: 228px; */
        margin-bottom: 30px;
    }

    .chooseCardOne {
        /* overflow: hidden !important; */
        /* width: min-content !important; */
        height: auto;
        /* width: 30% !important; */
        padding: 30px 10px 30px 10px!important;
        /* padding: 30px 21px; */
        /* margin-right: 20px; */
    }

    .cardImg {
        width: 227px;
        height: auto;
    }

    .chooseCardTitle {
        font-weight: 700;
        font-size: 20px;
    }

    .cardPrice {
        font-weight: 700;
        font-size: 29px;
        margin-bottom: 30px;
    }

    .perTitle {
        font-weight: 600;
        font-size: 8px;
    }

    .checkTitle {
        font-weight: 600;
        font-size: 14px;
        /* width: 340px; */
    }

    .checkPoint {
        width: 16px;
        height: auto;
    }

    .chooseCardContainer {
        margin-top: 90px;
        text-align: center;
        padding-bottom: 100px !important;
        justify-content: center;
    }

    .perTitle {
        margin-bottom: -10px;
    }

    .card-faq-subsection {
        padding: 5px 0px 40px !important;
    }

    .recommendedCard {
        margin-top: -27px;
        left: 50%;
        display: none;
    }

    .recommendedIcon{
        display: block;
        width: fit-content;
        position: absolute;
        margin-top: -31px;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .recommendedImg{
        width: 24px;
        height: auto;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .cardScreen-card {
        display: none;
    }

    .cardScreen-card-mob {
        display: block;
        text-align: center;
    }

    .chooseCardTitleOne{
        margin-bottom: 104px;
        margin-top: 0px;
    }

    .cardScreen-sub-title {
        color: #A6A7A8;
        font-size: 20px;
        margin-top: 40px;
        font-weight: 500;
        margin-bottom: 85px;
    }

    /* .cardDetailsContainer {
        display: flex;
        justify-content: center;
    } */

    .card-homeScreen-img {
        width: 350px;
        height: auto;
    }

    .card-homeScreen-title-container {
        gap: 50px;
        align-items: center;
        text-align: center;
    }
}

@media screen and (min-width: 1024px)  {

    .card-homeScreen-img {
        width: 350px;
        height: auto;
        margin-top: -240px;
    }

    .checkTitle{
        margin-bottom: 70px !important;
    }

    .checkPoint{
        margin-bottom: 0px !important;
    }

    .card-homeScreen-title-container {
        /* gap: 10px; */
    }

}