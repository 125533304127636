.pr-Media {
    background: transparent;
}

.bgImg {
    width: 100%;
    height: auto;
}

.bgImgContainer {
    width: 100%;
    height: 100%;
    position: absolute;
    margin-top: -180px;
}

.forbes-image-container {
    margin-left: 120px;
}

.featured-title {
    color: #FFFFFF;
    margin-bottom: 40px;
    font-size: 24px;
    font-weight: 700;
    padding-top: 160px;
}

.YComb-image {
    width: 270px;
    height: auto;
}

.FE-image {
    width: 332px;
    height: auto;
}

.forbes-image {
    width: 167px;
    height: auto;
}

.accel-image {
    width: 251px;
    height: auto;
}

.CNBC-image {
    width: 79.65px;
    height: auto;
}

.ET-image {
    width: 72px;
    height: auto;
}

.inc-image {
    width: 131px;
    height: auto;
}

.entrepreneur-image {
    width: 254.69px;
    height: auto;
}

.fp-image {
    width: 221.54px;
    height: auto;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .forbes-image-container {
        margin-top: 20px;
    }

    .ET-image {
        width: 45px;
        height: auto;
    }

    .bgImgContainer {
        width: 100%;
        height: 100%;
        position: absolute;
        margin-top: -100px;
    }

    .entrepreneur-image {
        width: 159.18px;
        height: auto;
    }

    .fp-image {
        width: 138.47px;
        height: auto;
    }

    .FE-image {
        width: 207.5px;
        height: auto;
    }

    .accel-image {
        width: 156.88px;
        height: auto;
    }

    .CNBC-image {
        width: 49.78px;
        height: auto;
    }

    .inc-image {
        width: 81.88px;
        height: auto;
    }

    .YComb-image {
        width: 168.75px;
        height: auto;
    }

    .forbes-image {
        width: 104.38px;
        height: auto;
    }

    .forbes-image-container {
        margin-left: 50px;
    }

    .featured-title {
        color: #FFFFFF;
        padding-top: 100px;
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 700;
    }
}