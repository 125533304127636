.rewards {
    margin-top: 200px;
    margin-bottom: 188px;
}

.rewards-title {
    background: linear-gradient(90deg, #F4EEE2 0%, #F5DCBC 16.66%, #F5DCBC 16.67%, #F6D5AC 23.44%, #F6D5AC 47.4%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-size: 44.8px;
    font-style: normal;
    font-weight: 700;
    line-height: 57.6px;
    /* 128.571% */
}

.reward-holder1 {
    margin-top: 100px;
    display: flex !important;
    gap: 40px;
}

.rewards-section-one {
    background-color: #ACACAC;
    border-radius: 100%;
    opacity: 0.1;
    width: 200px;   
    height: 200px;
    filter: blur(100px);
    position: absolute;
}

.reward-sectionOne {
    /* position: relative; */
    width: 560px !important;
    border: 3px solid transparent;
    border-radius: 20px;
    background: linear-gradient(180deg, #FFEDBE 0%, #EFCC97 100%);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    padding: 40px 50px 41px 50px;
    box-shadow: -8px -8px 12px rgba(255, 255, 255, 0.04), 8px 8px 12px rgba(0, 0, 0, 0.16);
}

.spend-earn-title {
    color: #393426;
    font-weight: 700;
    font-size: 56px;
    margin-top: 50px;
}

.spend-earn-subtitle {
    color: #646667;
    font-size: 24px;
    font-weight: 600;
    margin-top: 20px;
}

.redeem-title-container {
    display: flex;
    width: 695px !important;
    /* width: 100% !important; */
    flex-wrap: wrap;
    align-items: center;
    margin-top: 40px !important;
    border: 3px solid transparent;
    border-radius: 20px;
    background: linear-gradient(90deg, rgb(194, 209, 255) 0%, #A4B1F4 100%);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    padding: 40px 50px 40px 50px !important;
    box-shadow: -8px -8px 12px rgba(255, 255, 255, 0.04), 8px 8px 12px rgba(0, 0, 0, 0.16);
}

.redeem-title {
    color: #393426;
    font-weight: 700;
    font-size: 48px;
    margin-bottom: 15px;
}

.redeem-sub-title {
    color: #646667;
    font-size: 24px;
    font-weight: 600;
}

.forever-title-container {
    display: flex;
    width: 695px !important;
    flex-wrap: wrap;
    align-items: center;
    border: 3px solid transparent;
    border-radius: 20px;
    background: linear-gradient(90deg, #FFCFC9 0%, #EEA5A5 100%);
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    padding: 40px 50px 40px 50px !important;
    box-shadow: -8px -8px 12px rgba(255, 255, 255, 0.04), 8px 8px 12px rgba(0, 0, 0, 0.16);
}

.forever-title {
    color: #393426;
    font-weight: 700;
    font-size: 47px;
    margin-bottom: 15px;
}

.lifetime-subtitle {
    color: #646667;
    font-size: 24px;
    font-weight: 600;
}

.lifetime-title {
    color: #A6A7A8;
}

.spendEarnImg {
    max-width: 244px;
    height: auto;
}

.redemIcon {
    width: 160px;
    height: auto;
}

.lifetimeValidity {
    width: 160px !important;
    height: auto;
}

.reward-sectionTwo{
    margin: 0px !important;
}

@media screen and (min-width: 300px) and (max-width: 750px) {
    .forever-title-container {
        margin-top: 20px !important;
        padding: 30px !important;
        width: 100% !important;
    }

    .spend-earn-title {
        margin-top: 15px;
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .redeem-title {
        margin-top: 15px;
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .forever-title {
        margin-top: 15px;
        font-size: 36px;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .spendEarnImg {
        width: 206px !important;
        height: auto;
    }

    .redeem-title-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 30px;
        padding: 30px !important;
        width: 100px !important;
    }

    .rewards {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .rewards-title {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
    }

    .reward-holder1 {
        margin-top: 50px;
        flex-wrap: wrap !important;
        gap: 0px !important;
    }

    .spend-earn-subtitle {
        font-size: 20px;
        font-weight: 600;
    }

    .redeem-sub-title {
        font-size: 20px;
        font-weight: 600;
    }

    .lifetime-subtitle {
        font-size: 20px;
        font-weight: 600;
    }
}

@media screen and (max-width: 767px) {
    .spendEarnImg {
        margin-right: 20px;
    }

    .lifetimeValidity {
        margin-right: 20px;
    }

    .redemIcon {
        margin-right: 20px;
    }

    .redeem-title-container {
        margin-top: 20px !important;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100% !important;
    }

    .reward-sectionOne{
        width: 100% !important;
        padding: 30px;
    }

    .forever-title-container{
        width: 100% !important;
    }

    .reward-sectionTwo {
        margin-left: 0px !important;
    }

    .reward-holder1 {
        margin-top: 50px;
        flex-wrap: wrap !important;
        gap: 0px !important;
    }
}

@media screen and (min-width: 1000px) and (max-width: 2560px) {
    .spendEarnImg {
        /* margin-left: -20px; */
    }

    .redemIcon {
        margin-right: 20px;
    }

    .lifetimeValidity {
        margin-right: 30px;
    }

}

@media screen and (min-width: 768px) and (max-width: 1025px) {

    .spend-earn-title{
        margin-top: 0px;
    }

    .forever-title-container {
        margin-top: 20px !important;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        align-items: center;
        padding: 30px;
        width: 100% !important;
    }

    .reward-sectionOne{
        display: flex;
        gap: 30px;
        padding: 30px;
        width: 100% !important;
    }

    .redeem-title-container {
        margin-top: 20px !important;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 30px;
        padding: 30px;
        width: 100% !important;
    }

    .reward-sectionTwo {
        margin-left: 0px !important;
    }

    .rewards {
        margin-top: 100px;
        margin-bottom: 50px;
    }

    .rewards-title {
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
    }

    .reward-holder1 {
        margin-top: 50px;
        flex-wrap: wrap !important;
        gap: 0px !important;
    }
}