.valueSubContainer {
    position: relative;
    margin-top: 20px;
    padding-top: 100px;
}

.value {
    background-color: #0F0F0F;
    /* padding-top: 70px; */
    padding-bottom: 120px;
}

.visionBgImg {
    position: absolute;
    width: 35%;
    right: 190px;
    margin-top: 80px;
}

.vision-dots {
    position: relative;
}

.vision-dots2 .visionBgImg2 {
    position: revert;
    width: 40%;
    margin-left: 64px;
    margin-top: -145px;
}

.TeamSectionContainer{
    margin-bottom: 200px;
}

@media screen and (max-width: 940px) {
    .value {
        padding-bottom: 49px;
    }
    .debCardContainer{
        padding-top: 80px;
    }
    
    .TeamSectionContainer{
        margin-bottom: 150px;
    }
}

@media screen and (max-width: 1020px) {
    .vision-dots {
        display: none;
    }
    .vision-dots2 {
        display: none;
    }

    .TeamSectionContainer{
        margin-bottom: 150px;
    }
}