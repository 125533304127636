.faq-container {
    background-color: #0f0f0f !important;
}

.faq-subsection {
    padding-bottom: 113px;
    text-align: center;
    /* padding-top: 270px; */
}

.debSection {
    position: absolute;
    margin-top: -400px;
}

.faq-title {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 48px;
    margin-bottom: 25px;
    text-align: center;
}

.faq-subtitle {
    color: #A6A7A8;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 40px;
    padding: 0px 220px;
    text-align: center;
}

.goto-mail {
    color: #FFFFFF;
}

.faq-navbar {
    background-color: #141516;
    border: 2px solid rgba(255, 255, 255, 0.05);
    /* padding-top: 20px; */
    /* padding-bottom: 15px; */
    padding: 0px 30px 30px 30px;
    border-radius: 20px;
    /* box-shadow: 8px 8px 12px 0px rgba(0, 0, 0, 0.16), -8px -8px 12px 0px rgba(255, 255, 255, 0.04); */
    font-size: 16px;
    height: 330px;
    overflow-y: scroll;
    /* overflow: hidden; */
}

.faq-navbar::-webkit-scrollbar {
    display: block;
    width: 2px;
    border: none;
}

.faq-navbar::-webkit-scrollbar-thumb{
    background-color: #A6A7A8;
    border-radius: 100px;
}

.faq-navbar::-webkit-scrollbar-track {
    background: transparent;
    background-color: #343739;
    border-radius: 100px;
    margin-top: 40px;
    margin-bottom: 40px;
}


@media screen and (min-width: 320px) and (max-width: 768px) {
    .faq-subsection {
        padding: 100px 0px 60px;
        text-align: left;
    }
    .debSection {
        position: absolute;
        margin-top: -435px;
    }
    .faq-subtitle {
        margin-bottom: 40px;
        font-size: 18px;
        font-weight: 500;
        padding: 0px !important;
        text-align: center;
    }
    .faq-title {
        font-weight: 700;
        font-size: 36px;
        margin-bottom: 20px;
        text-align: center;
    }
    .faq-container {
        margin-top: 0px;
        display: flex !important;
        flex-wrap: wrap !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .faq-navbar{
        height: auto;
        padding: 0px;
    }
    .faq-navbar::-webkit-scrollbar{
        display: none;
    }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
    .faq-subsection {
        padding: 100px 0px 60px;
        text-align: center;
    }
    .debSection {
        position: absolute;
        margin-top: -380px;
    }

    .faq-subtitle {
        padding: 0px 85px;
    }

    .faq-title {
        font-weight: 700;
        font-size: 36px;
        margin-bottom: 20px;
    }
}
