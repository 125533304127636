.aboutCompnyTitleContainer{
    margin-bottom: -82px;
}
.abtCompanyContainer {
    background-color: #212426;
}

.aboutCompnyTitle {
    font-size: 64px;
    font-weight: 700;
    color: #FFFFFF;
    margin-bottom: 50px;
    margin-top: 83px;
}

.aboutCompnySubtitle {
    font-size: 24px;
    font-weight: 500;
    color: #A6A7A8;
}

.aboutCompnySubtitleCEO {
    font-size: 24px;
    color: #FFFFFF;
    font-weight: 500;
    margin-top: 30px;
}

@media screen and (max-width: 940px) {
    .aboutCompnyTitleContainer{
        margin-bottom: 70px;
    }
    .aboutCompnyTitle {
        font-size: 36px;
        font-weight: 700;
    }
    .aboutCompnySubtitleCEO{
        /* margin-bottom: -72px; */
        margin-top: 15px;
    }
    .abtDebit{
        margin-right: -20px;
    }
}