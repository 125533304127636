.perseveranceTitle {
    color: #212426;
    font-weight: 700;
    font-size: 40px;
    letter-spacing: -0.02em;
    margin-top: 10px;
    text-align: left;
}

.perseveranceSubTitle {
    color: #646667;
    font-weight: 600;
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 50px;
    text-align: left;
    line-height: 28px;
}

.perseverance {
    box-shadow: -8px -8px 12px rgba(255, 255, 255, 0.04), 8px 8px 12px rgba(0, 0, 0, 0.16);
    padding: 25px 35px;
    border-radius: 14px;
    margin-right: 5px;
    min-width: 32%;
    height: 505px;
    text-align: left;
}

.perseveranceHblue {
    width: 120px;
    height: auto;
    margin-left: -25px;
}

@media screen and (max-width: 1020px) {
    .perseverance {
        margin-top: 0px !important;
        text-align: left;
    }
    .perseverance {
        margin-bottom: 30px !important;
        height: auto;
    }
    .perseveranceSubTitle{
        text-align: left;
    }
}

@media screen and (max-width: 940px) {
    .perseveranceSubTitle {
        margin-bottom: 0px;
        font-size: 16px;
    }
    .perseveranceHblue {
        width: 80px;
        height: auto;
        margin-left: -20px;
    }
    .perseveranceTitle {
        font-size: 32px;
    }
}

@media screen and (min-width: 1023px) and (max-width: 1400px) {
    .perseverance {
        text-align: left;
        line-height: 28px;
    }
}