.shopAndEarn-title {
    font-weight: 700;
    font-size: 50px;
    color: #FFFFFF;
    margin-bottom: 15px;
}

.shopAndEarnLinearOne {
    background: linear-gradient(90deg, #FBCC65 0%, #FBCC65 0.01%, #FBCC65 26.04%, #E59212 80.4%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.shopAndEarnLinear {
    background: linear-gradient(90deg, #FBCC65 0%, #FBCC65 0.01%, #FBCC65 26.04%, #E59212 80.4%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.sub-section {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 248px;
}

.shopAndEarn {
    /* background: linear-gradient(0deg, #25143A 34.46%, rgba(37, 20, 58, 0.02) 0%); */
    /* background: linear-gradient(180deg, rgba(198, 194, 204, 0.02) 70%, rgba(199, 192, 207, 0.09) 34.46%, rgba(114, 91, 141, 0.02) 85%); */
    
    /* background: linear-gradient(0deg, rgba(37, 20, 58, 0.8) 85%, rgba(37, 20, 58, 0.02) 94.46%, rgba(37, 20, 58, 0.02) 5%); */
    background: linear-gradient(180deg, rgba(37, 20, 58, 0.02) 0%, rgba(37, 20, 58, 0.8) 34.46%, rgba(37, 20, 58, 0.02) 85%) !important;

}

.shopAndEarn-subtitle {
    font-weight: 400;
    font-size: 28px;
    color: #A6A7A8;
    line-height: 120%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center !important;
    gap: 5px;
}

.learn-more-link {
    color: #D1964A !important;
    text-decoration: none;
    font-weight: bold;
}

.shopAndEarn-img {
    width: 713px;
    height: auto;
}

.right-arrow {
    margin-left: 10px;
}

.bitcoinTitleTwo {
    background: linear-gradient(to right, #F4EEE2, #F6D5AC);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    font-size: 48px;
}

.imag-container {
    margin-top: 132px;
    /* background: linear-gradient(0deg, #25143A 34.46%, rgba(37, 20, 58, 0.02) 0%); */
}

.rewardCashbackTitleContainer {
    margin-top: 200px;
    /* background: linear-gradient(180deg, #25143A 34.46%, rgba(37, 20, 58, 0.02) 85%); */
}

.rewardCashbackTitleOne {
    background: linear-gradient(90deg, #D7A65D 0%, #AA853D 0.01%, #F9BC70 26.04%, #FFD770 47.4%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}

.rewardCashbackSubTitleOne {
    color: #FFF;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 25px;
}

.rewardCashbackDivider {
    opacity: 0.2;
    background: linear-gradient(301deg, #8E6702 29.02%, rgba(15, 15, 15, 0.00) 81.55%), linear-gradient(90deg, #D7A65D 0%, #AA853D 0.01%, #F9BC70 26.04%, #FFD770 47.4%), #000;
    width: 100%;
    height: 1px;
    margin: 100px 0px;
}

.landingSatsPayContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 160px;
}

.landingScreenSatspayImg{
    width: 513px;
    height: auto;
}

.landingSatsPayTitle{
    background: linear-gradient(96.17deg, #FBCC65 0%, #E59212 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #FFFFFF;
    font-size: 64px;
    font-weight: 700;
    line-height: 77px;
}

.landingSatsPaySubTitle{
    font-size: 30px;
    line-height: 45px;
    font-weight: 600;
    color: #FFFFFF;
    margin-top: 50px;
}

.landingSatsPaySubContainer{
    background: linear-gradient(136.4deg, #C39E4B -24.28%, #29200C 37.91%, #1F1806 115.62%);
    border-radius: 20px;
    border: 1px solid #463514;
    width: 595px;
    height: auto;
    padding: 48px;
    text-align: left;
}

@media screen and (min-width: 300px) and (max-width: 940px) {

    .rewardCashbackTitleContainer {
        margin-top: 0px;
    }

    .rewardCashbackTitleOne {
        font-size: 20px;
    }

    .rewardCashbackSubTitleOne {
        font-size: 18px;
        margin: 10px 56px 0px 56px;
    }

    .rewardCashbackDivider {
        margin: 25px 0px;
    }

    .shopAndEarn-img {
        width: 270px;
        height: auto;
        padding-bottom: 100px;
    }

    .sub-section {
        padding-top: 100px !important;
    }

    .shopAndEarn-title {
        font-weight: 700;
        font-size: 26px;
        line-height: 127%;
        color: #FFFFFF;
        margin-bottom: 22px;
    }

    .imag-container {
        margin-top: 46px;
        display: flex;
        justify-content: center;
    }

    .shopAndEarn-subtitle {
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: 500;
    }

    .shopAndEarnLinear {
        font-size: 20px;
    }

    .shopAndEarnLinearOne {
        font-size: 26px;
    }

    .bitcoinTitleTwo {
        background: linear-gradient(to right, #F4EEE2, #F6D5AC);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
        font-size: 36px;
    }

    .landingSatsPayContainer{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-top: 0px;
    }

    .landingSatsPaySubContainer{
        background: linear-gradient(136.4deg, #C39E4B -24.28%, #29200C 37.91%, #1F1806 115.62%);
        border-radius: 20px;
        border: 1px solid #463514;
        width: 100%;
        height: auto;
        padding: 30px 20px;
        text-align: left;
    }

    .landingSatsPayTitle{
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
    }

    .landingSatsPaySubTitle{
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        margin-top: 15px;
    }

    .landingScreenSatspayImg{
        width: 297px;
        height: auto;
        margin-top: 30px;
        margin-bottom: 100px;
    }
}