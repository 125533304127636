.accordion {
    margin-top: 25px;
}

.title {
    font-size: 18px;
    color: #FFFFFF;
    font-weight: bold;
    line-height: 1;
    text-align: left;
    padding: 1rem 0;
}

.subText {
    font-size: 14px !important;
    color: #A6A7A8;
    font-weight: 400;
    line-height: 2;
    text-align: left;
    z-index: 409;
    margin-right: 25px;
    font-size: 14px;
    opacity: 0.6;
}