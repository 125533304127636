.teamTitle {
    text-align: center;
    margin-top: 120px;
}

.teamHeadder {
    color: #FFFFFF;
    font-weight: 700;
}

.teamSubTitle {
    color: #A6A7A8;
    font-weight: 500;
    font-size: 18px;
}

@media screen and (max-width: 940px) {
    .teamTitle {
        margin-top: 50px;
        padding: 0px 20px 0px;
    }
    .teamSubTitle {
        margin-bottom: -70px;
    }
}