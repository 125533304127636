.visionTitle {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 48px;
    margin-bottom: 20px;
}

.vision-container {
    margin-bottom: 102px;
    margin-top: 100px;
}

.visionSubTitle {
    color: #A6A7A8;
    padding-left: 45px;
    font-size: 18px;
}

.visionSubTextHolder {
    padding-right: 45px;
}

.visionSub {
    color: #A6A7A8;
    font-size: 18px;
    font-weight: 500;
}


/* @media screen and (max-width: 500px) {
    .visionSubTitle {
        padding-left: 0px;
        margin-top: 20px;
    }
} */

.vision-team-container {
    position: relative;
}

.visionDots {
    position: absolute;
    width: 25%;
    /* margin-left: 50px; */
}

.visionDots2 {
    position: absolute;
    width: 25%;
    right: 0px;
    margin-top: -130px;
    /* margin-right: 60px; */
    z-index: 0;
    /* margin-top: 290px; */
}

.visionImg2 {
    filter: drop-shadow(-8px -8px 12px rgba(255, 255, 255, 0.04)) drop-shadow(8px 8px 12px rgba(0, 0, 0, 0.16));
    border-radius: 14px;
    display: flex;
    justify-content: center;
    width: 1122px;
    height: auto;
    z-index: 1;
}

@media screen and (min-width: 760px) and (max-width:1200px) {
    .visionImg2 {
        display: flex;
        justify-content: center;
        width: 750px !important;
        height: auto;
    }
}

.visionImgMob {
    width: 370px;
    height: auto;
}

.imageMobile {
    display: flex;
    justify-content: center;
}

.image2 {
    display: flex;
    justify-content: center;
}

.visionImg3 {
    filter: drop-shadow(-8px -8px 12px rgba(255, 255, 255, 0.04)) drop-shadow(8px 8px 12px rgba(0, 0, 0, 0.16));
    border-radius: 14px;
}

.ourVisionTitle {
    color: #FFFFFF;
    font-weight: 700;
    margin-top: 20px;
}

.ourMisionTitle {
    color: #FFFFFF;
    font-weight: 700;
    margin-top: 20px;
}

.ourMisionSubtitle {
    color: #A6A7A8;
    font-weight: 500;
    margin-top: 18px;
}

.ourVisionSubtitle {
    color: #A6A7A8;
    font-weight: 500;
    margin-top: 20px;
}

.hred {
    width: 120px;
    height: auto;
}

.hblue {
    width: 120px;
    height: auto;
}

@media screen and (max-width: 940px) {
    .visionImg2 {
        margin-top: 0px;
        z-index: 999;
    }
    .images2 {
        margin-top: 0px;
    }
    .dot {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
    }
    .visionSubTitle {
        padding-left: 0px;
    }
    .visionDots {
        /* display: none; */
        margin: -8px 40px 0px;
        z-index: 9;
    }
    .visionDots2 {
        /* display: none; */
        margin: -65px 40px 0px 0px;
        z-index: 9;
    }
    .visionSubTextHolder {
        text-align: start;
        padding-right: 0px;
    }
    .visionTitle {
        font-size: 36px;
        font-weight: 700;
    }
    .vision-container {
        margin-top: 96px;
        margin-bottom: 10px;
    }

    .vision-container2{
        margin-top: 10px;
    }

    .hred {
        width: 80px;
        height: auto;
    }
    .hblue {
        width: 80px;
        height: auto;
    }
    .visionSub {
        color: #A6A7A8;
        font-size: 18px;
        font-weight: 500;
    }
    .visionSubTitle {
        font-size: 18px;
        font-weight: 500;
        margin-top: 0px;
    }
    .ourVisionTitle {
        color: #FFFFFF;
        font-weight: 700;
        margin-top: 0px;
    }
    .ourMisionTitle {
        margin-top: 0px;
    }
    .valueSubContainer {
        padding-top: 0px;
    }
    .vision-team-container {
        position: relative;
        margin-top: 45px;
    }
}

@media screen and (max-width: 750px) {
    .image2 {
        display: none;
    }
}

@media screen and (min-width: 768px) {
    .imageMobile {
        display: none;
    }

    .vision-container {
        margin-top: 96px;
    }
}