    .spinRewardPopUp {
        box-shadow: -8px -8px 12px rgba(255, 255, 255, 0.04), 8px 8px 12px rgba(0, 0, 0, 0.16);
        border-radius: 10px;
        text-align: center;
        position: relative;
    }
    
    .popUpSImg {
        display: flex;
        justify-content: center;
    }
    
    .popImg {
        width: 40%;
        background: #FFFFFF;
        box-shadow: -8px -8px 12px rgba(255, 255, 255, 0.04), 8px 8px 12px rgba(209, 150, 74, 0.3);
        border-radius: 100%;
        align-self: center;
        margin-top: -90px;
    }
    
    .popUpTop {
        height: 140px;
        background: linear-gradient(90deg, #F4EEE2 0%, #F6D5AC 100%);
        border-radius: 10px 10px 0px 0px;
    }
    
    .cong-sats {
        color: rgb(247, 2, 2);
    }
    
    .popUptitle {
        font-weight: 700;
        font-size: 24px;
        margin-top: 25px;
        align-self: center;
    }
    
    .popUpsubTitle {
        color: #212426;
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        padding: 22px;
    }
    
    .modal .modal-content {
        max-width: 400px;
    }
    
    .modal-dialog {
        display: flex;
        justify-content: center;
    }