.satsPayContainer {
    margin-top: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.satsPayTitleContainer {
    width: 560px;
}

.satsPayTitle {
    font-size: 64px;
    font-weight: 700;
    background: linear-gradient(96.17deg, #FBCC65 0%, #E59212 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #FFFFFF;
    font-size: 64px;
    font-weight: 700;
    line-height: 76px;
}

.satsPaySubTitle {
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    color: #FFFFFF;
    margin-top: 40px;
}

.satsPayImg {
    width: 591px;
    height: auto;
}

.ScanAndPayFutrSectContainer {
    margin-top: 120px;
    align-items: center;
}

.satsPayFutrSectTitle {
    font-size: 45px;
    font-weight: 700;
    background: linear-gradient(96.17deg, #FBCC65 0%, #E59212 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #FFFFFF;
    line-height: 57px;
}

.satsPayFutrSectSubTitleContainer {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin-top: 50px;
}

.satsPayFutrSectSubSec {
    background: #402605;
    border: 1px solid #4D4126;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 40px;
}

.satsPayFutrSectSubTitle {
    font-size: 32px;
    line-height: 45px;
    font-weight: 500;
    width: 300px;
    height: auto;
    color: #FFFFFF;
}

.satsPaySubImg {
    width: 167px;
    height: auto;
}

.satsPayFAQSectTitle {
    font-size: 40px;
    font-weight: 700;
    background: linear-gradient(96.17deg, #FBCC65 0%, #E59212 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #FFFFFF;
    line-height: 57px;
}

.satsPayFAQSectSubTitle {
    color: #FFFFFF;
    font-size: 30px;
    font-weight: 500;
    line-height: 45px;
    margin-top: 25px;
}

.satsPayFAQImg {
    width: 423px;
    height: auto;
}

.ScanAndPayFAQTitle {
    width: 673px;
    height: auto;
}

.ScanAndPayFAQSectContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 64px;
    margin-top: 120px;
}

@media screen and (min-width: 300px) and (max-width: 940px) {
    .satsPayContainer {
        margin-top: 80px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .satsPayImg {
        width: 305px;
        height: auto;
    }

    .satsPayTitle {
        font-size: 32px;
        padding: 0px 50px;
        line-height: 41px;
    }

    .satsPaySubTitle {
        font-size: 24px;
        padding: 0px 30px;
        line-height: 31px;
        margin-top: 25px;
        margin-bottom: 50px;
    }

    .ScanAndPayFutrSectContainer {
        margin-top: 80px;
        align-items: center;
    }

    .satsPayFutrSectTitle {
        font-size: 20px;
        line-height: 32px;
        text-align: center;
    }

    .satsPaySubImg {
        width: 85px;
        height: auto;
    }

    .satsPayFutrSectSubTitle {
        font-size: 16px;
        line-height: 23px;
    }

    .satsPayFutrSectSubSec {
        padding: 35px 15px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        padding: 40px;
        width: 100% !important;
    }

    .satsPayFutrSectSubTitleContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 40px;
        width: 100% !important;
        margin-top: 30px !important;
    }

    .ScanAndPayFAQSectContainer {
        margin-top: 80px;
    }

    .satsPayFAQImgContainer{
        display: none;
    }

    .satsPayFAQSectTitle{
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
    }

    .satsPayFAQSectSubTitle{
        font-size: 18px;
        font-weight: 500;
        line-height: 23px;
    }
}