        .bitcoin-value {
            color: #646667;
            font-size: 18px;
            font-weight: 600;
        }
        
        .rupees-title1 {
            color: #A6A7A8;
            font-weight: bold;
            font-size: 36px;
            margin-top: 40px;
        }
        
        .sectionOne-titleColor {
            background: linear-gradient(to right, #F4EEE2, #F6D5AC);
            background-clip: text;
            /* -webkit-background-clip: text; */
            -webkit-text-fill-color: transparent;
            /* color: #FFE099; */

           /*  background-image: url(/src/images/textBg.png);
            background-clip: text;
            -webkit-text-fill-color: transparent; */
        }
        
        .rupees-title {
            color: #A6A7A8;
            font-weight: bold;
            font-size: 18px;
            margin-top: 20px;
        }
        
        .bitcoinChart {
            margin-top: 169px;
            position: relative;
        }
        
        .before-title {
            color: #646667;
            font-weight: 600;
        }

        .before{
            margin-top: 40px;
        }
        
        .years-title {
            color: #A6A7A8;
            font-size: 18px;
            font-weight: bold;
        }
        
        .b-sectionOne {
            margin-bottom: 50px;
            padding: 0px !important;
        }
        
        .value-bitcoin-slider {
            background: #343739;
            height: 2px;
            width: 65%;
            -webkit-appearance: none;
        }
        
        input[type='range']::-webkit-slider-thumb {
            width: 18px;
            -webkit-appearance: none;
            height: 18px;
            background: #212426;
            border: 2px solid #FFE099;
            border-radius: 100%;
        }
        
        .amount {
            color: #FFE099;
            font-weight: bold;
            font-size: 36px;
            margin-left: 10px;
            border: none;
            background-color: #212426;
            overflow: hidden;
        }
       
        .amount::-webkit-outer-spin-button,
        .amount::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        .amount:focus{
            outline: none;
        }

        .amntcolor {
            color: #FFE099;
        }
        
        .year {
            color: #FFE099;
            font-weight: bold;
            font-size: 36px;
            margin-right: 10px;
        }
        
        .sectionOne-title {
            color: #FFFFFF;
            font-weight: bold;
            font-size: 46px;
            margin-bottom: 50px;
        }
        
        .sectionOne-subtitle {
            color: #A6A7A8;
        }
        
        .sectionTwo {
            border: 3px solid transparent;
            border-radius: 20px;
            background: linear-gradient(to right, #212426, #212426), linear-gradient(to left, #171717, #343434);
            background-clip: padding-box, border-box;
            background-origin: padding-box, border-box;
            /* padding: 30px; */
            box-shadow: -8px -8px 12px rgba(255, 255, 255, 0.04), 8px 8px 12px rgba(0, 0, 0, 0.16);
            overflow: hidden;
        }
        
        .b-price {
            color: #FFE099;
            font-size: 70px;
            font-weight: 700;   
        }
        
        .sectiontwo-title {
            color: #FFFFFF;
            font-size: 24px;
            font-weight: bold;
            /* margin-top: 40px; */
        }
        
        .price-container {
            margin-top: 20px;
            margin-bottom: 50px;
        }
        
        .display-container {
            padding: 48px 24px 64px 24px;
        }
        
        .sectiontwo-sub-title {
            color: #646667;
            font-weight: 700;
            font-size: 16px;
        }
        
        .get-started-butn {
            background-color: #D1964A;
            padding: 10px;
            border-radius: 40px;
            font-size: 16px;
            color: #FFFFFF;
            font-weight: bold;
            border: none;
            margin-top: 10px;
            position: absolute;
        }
        
        @media screen and (min-width: 300px) and (max-width: 750px) {
            .sectiontwo-title {
                font-size: 24px;
            }

            .b-sectionOne {
                margin-bottom: 47px;
            }

            .value-bitcoin-slider {
                background: #343739;
                height: 2px;
                width: 100%;
                -webkit-appearance: none;
            }

            .sectionHolder {
                /* position: relative; */
                border: 3px solid transparent;
                border-radius: 14px;
                background: linear-gradient(to right, #212426, #212426), linear-gradient(to left, #171717, #343434);
                background-clip: padding-box, border-box;
                background-origin: padding-box, border-box;
                box-shadow: -8px -8px 12px rgba(255, 255, 255, 0.04), 8px 8px 12px rgba(0, 0, 0, 0.16);
                overflow: hidden;
                border-radius: 14px;
                padding: 10px;
                margin: 0px !important;
        
            }

            .amount{
                margin-left: 3px;
            }

            .bitcoinChart {
                margin-top: 80px;
            }
            .rupees-title1 {
                font-size: 26px;
                margin-top: 15px;
            }
            .price-container{
                margin-top: 15px;
            }
            .b-price {
                font-size: 10.2vmin;
                /* font-size: 46px; */
            }
            .rupees-title {
                font-size: 26px;
                margin-top: 13px;
            }
            .sectionTwo {
                /* margin-top: 40px !important; */
                border: none;
                border-radius: 20px;
                background: none;
                background-color: #2A2E31;
                box-shadow: none;
                overflow: hidden;
                padding: 20px 15px 23px 19px;
            }

            .display-container {
                padding: 0px;
            }

            .sectionOne-title {
                font-weight: 700;
                font-size: 36px;
                margin-bottom: 25px;
            }
            .sectionOne-titleColor{
                background: #FFFFFF;
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }

        }