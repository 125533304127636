.footer-logo {
    width: 111px;
    height: auto;
}

.comTitle {
    font-size: 48px;
    font-weight: 700;
    color: #FFFFFF;
}

.comSubTitle {
    font-size: 18px;
    font-weight: 500;
    color: #A6A7A8;
    margin-top: 20px;
    margin-bottom: 50px;
}

.footerImg {
    width: 300px;
    height: auto;
    margin-bottom: 50px;
}

.footerImgMob{
    display: none;
}

.comButtonHolder {
    margin-right: 30px;
}

.comButtom {
    border: 1.5px solid #465357;
    /* margin-right: 30px; */
    margin-bottom: 30px;
    position: relative;
    /* display: flex;
    justify-content: center;
    align-content: center; */
    padding: 15px 30px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    background-color: #2B3437;
    border-radius: 40px;
}

.comButtomTweet {
    border: 1.5px solid #465357;
    margin-right: 0px;
    position: relative;
    padding: 15px 30px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    background-color: #2B3437;
    border-radius: 40px;
    margin-bottom: 30px;
}

.comButtomWhatsApp {
    border: 1.5px solid #465357;
    /* margin-right: 30px; */
    position: relative;
    padding: 15px 30px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    background-color: #293028;
    border: 1.5px solid #3E4D3E;
    border-radius: 40px;
    margin-bottom: 30px;
}

.comTel {
    margin-right: 13px;
    color: #499BE4;
    width: 21.94px;
    height: auto;
}

.comTweet {
    color: #499BE4;
    margin-right: 13px;
    width: 16.85px;
    height: auto;
}

.comWhatsApp {
    color: #2BB826;
    margin-right: 13px;
    width: 23.49px;
    height: auto;
}

.footer-sectionOne{
    gap: 45px
}

/* .footerContant-holder {
    margin-left: 80px;
} */

.footer-community {
    margin-bottom: 170px;
    justify-content: space-between !important;
}

.footerSection {
    margin-top: 130px;
}

.footer-sectionOne-content {
    color: #FFFFFF;
}

.divider {
    border: 1px solid white;
    opacity: 0.1;
}

.footer-Social-media {
    border: 1.5px solid #2E3133;
    border-radius: 100%;
    padding: 6px 9px;
}

.footer-icon {
    margin-bottom: 2px;
}

.copy-right {
    color: #A6A7A8;
    text-align: right;
}

.footer-links:hover {
    color: #FFE099 !important;
    text-decoration: none;
}

.footerSection .copy-right-title {
    line-height: 21px;
    margin-bottom: 0;
}

.footerSection .gst-info {
    color: #646667;
    font-size: 14px;
}

/* .footer-links:active {
    color: #FFE099;
    border-bottom: 1px solid #FFE099;
}
 */

.footer-links {
    color: #FFFFFF !important;
    text-decoration: none;
}

.footerImg-holder{
    display: none !important;
}

@media screen and (min-width:320px) and (max-width:425px) {
    .koo {
        margin-top: 20px;
    }

    .footer-sectionOne{
        gap: 0px
    }

    .footerImgMob{
        display: none;
    }

    .footerImg-holder{
        display: block !important;
    }
}

@media screen and (min-width:320px) and (max-width:940px) {
    .footer-Social-media {
        border: 1.5px solid #2E3133;
        border-radius: 100%;
        padding: 7px 10px;
    }

    .footer-sectionOne-content {
        margin-bottom: 10px;
    }

    .footer-media-container {
        margin-bottom: 20px;
    }

    .footer-appStoreLogo {
        margin-top: 10px;
    }

    .footer-googlePlayLogo {
        margin-top: 10px;
    }

    .footer-chromeWebStoreLogo {
        margin-top: 13px;
    }

    .footer-logo {
        margin-bottom: 10px;
    }

    .koo-icon {
        margin-top: 30px;
    }

    .footer-media-container div {
        margin: 1rem 0;
    }

    .footerSubSection {
        display: flex !important;
        justify-content: center !important;
        align-content: center !important;
        text-align: center;
    }

    .footerImgMob{
        display: block;
        width: 240px;
        height: auto;
        text-align: center;
    }

    .footerImg-holder {
        /* margin-left: auto;
        margin-right: auto; */
        margin-bottom: 20.48px;
        display: grid !important;
        justify-items: center;
        align-items: center;
        align-self: center;
    }

    .footerSection {
        margin-top: 60px;
    }

    .comTitle {
        font-size: 39px;
        font-weight: 700;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        text-align: center;
        /* text-align: center; */
    }

    .comSubTitle {
        margin-bottom: 35px;
    }

    .comButtom {
        padding: 12px 60px;
        margin-right: 0px !important;
        width: 300px;
    }

    .comButtomWhatsApp {
        padding: 12px 60px;
        margin-right: 0px;
        /* margin-top: 30px; */
        /* width: 300px; */
    }

    .comButtomTweet {
        padding: 12px 57.5px;
        margin-right: 0px;
        /* margin-top: 30px; */
        width: 300px;
    }

    .footer-community {
        margin-bottom: 70px;
        justify-content: center !important;
    }

    .footerImg {
        display: none; 
    }

    .comSocialmedia {
        justify-content: center;
    }

    .comButtonHolder {
        margin-right: 0px;
    }

    .footer-sectionOne{
        gap: 0px
    }
}

@media screen and (min-width:769px) and (max-width:1440px) {
    .copy-right {
        text-align: end;
    }

    .footer-downloadLinks{
        margin-top: 20px !important;
        
    }

    .footer-sectionOne{
        gap: 0px;
        justify-content: space-between;
    }

    .footerImg-holder {
        /* margin-left: auto;
        margin-right: auto; */
        margin-bottom: 20.48px;
        display: grid;
        justify-items: center;
        align-self: center;
    }
}

