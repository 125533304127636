.abtMainSection {
    /* background-color: #2A2E31; */
    /* padding-bottom: 140px; */
    padding-top: 104px;
}

.AbtScreen {
    display: flex;
    justify-content: center;
}

.AbtSectionOne {
    align-items: center;
    position: relative;
}

.web3 {
    background: linear-gradient(90deg, #FBCC65 0%, #FBCC65 0.01%, #FBCC65 26.04%, #E59212 80.4%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.abtbg {
    background-color: #ACACAC;
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 100%;
    /* margin-top: 100px; */
    /* margin-left: 30px; */
    opacity: 0.1;
    width: 400px;
    height: 400px;
    filter: blur(200px);
    position: absolute;
}

.AbtHeadderTitle {
    text-align: center;
    font-weight: 700;
    font-size: 64px;
    color: #FFFFFF;
}

.AbtSubTitle {
    color: #FFFFFF;
    text-align: center;
    font-weight: 500;
    font-size: 32px;
    margin-top: 20px;
}

.AbtTitleSection {
    margin-top: 100px;
    /* padding-bottom: 180px; */
    text-align: center;
}

.AbtTitleSection .col-lg-7 {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

@media screen and (max-width:940px) {
    .AbtHeadderTitle {
        font-size: 50px;
    }
    
    .AbtSubTitle {
        font-size: 18px;
        font-weight: 500;
        color: #A6A7A8;
    }
    .AbtSectionOne {
        height: 0px;
    }
    .abtMainSection {
        padding-bottom: 0px;
    }

    .AbtTitleSection{
        margin-top: 0px;
    }
    /* .AbtScreen {
        padding-bottom: 100px;
    } */
}