.accordion {
    background-color: #141516;
}

.accordion-title {
    cursor: pointer;
}

.toggleBtnOpen {
    font-size: 20px;
    font-weight: bold;
    width: 2rem;
    height: 2rem;
    background-color: #b58648;
    border-radius: 50%;
    color: #2A2E31;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    margin-left: 10px;
}

.toggleBtnClose {
    font-size: 20px;
    font-weight: bold;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    border: 2px solid #A6A7A8;
    color: #A6A7A8;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    margin-left: 10px;
}