    .tab {
        font-size: 16px;
        color: #ffffff !important;
        font-weight: bold;
        line-height: 1.286;
        text-align: left;
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }



    .navLinkContainer {
        position: sticky;
        top: -1px;
        width: 100%;
        background-color: #141516 !important;
        z-index: 999;
    }

    .navLink {
        padding: 29px 10px 10px 10px;
        text-decoration: none;
        color: #A6A7A8;
        font-weight: 600;
        background: transparent;
        border: none;
        border-bottom: 2px solid transparent;
        z-index: 1 !important;
        position: relative;
      }
      
      .navLink:hover {
        color: #FFE099;
      }

      .navLink.active {
        color: #FFE099;
        text-decoration-thickness: 2px ! important;
        border-bottom: 2px solid #FFE099;
        z-index: 2 !important;
      }
      
      .navDivider {
        border-top: 2px solid #343739;
        position: relative;
        /* top: -2px; */
        z-index: 1 !important;
      }

    @media screen and (min-width: 320px) and (max-width: 767px) {
        .faq-link {
            overflow-x: scroll;

        }

        .linear {
            background: linear-gradient(265.31deg, #141516 23.69%, rgba(41, 46, 49, 0) 86.38%);
            height: 22px;
            width: 55px !important;
            position: absolute;
            margin-top: 30px;
            z-index: 999;
            right: 0px;

        }

        .faq-link::-webkit-scrollbar {
            display: none;
        }
    }