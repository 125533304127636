.spinWheel-container {
    background: url('../../images/spinWheel-Background.png') no-repeat;
    width: 100%;
    background-size: cover;
}

.subSecton {
    padding-top: 50px;
    padding-bottom: 50px;
}

.spinWheel-title {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 60px;
}

.spinWheel-subtitle {
    color: #A6A7A8;
}

.wheel {
    width: 325px;
    height: 325px;
    position: absolute;
    z-index: 1;
    margin-top: 62px;
}

.wheelBg {
    width: 450px;
    height: 450px;
    z-index: 1;
}

.marker {
    position: absolute;
    width: 70px;
    height: 90px;
    z-index: 2;
}

.wheelLogo {
    margin-top: 180px;
    margin-left: 12px;
    width: 100px;
    height: 100px;
    position: absolute;
    z-index: 3;
}

.button {
    padding: 10px;
    color: #FFFFFF;
    font-weight: bold;
    border: none;
    background-color: #D1964A;
    border-radius: 40px;
    border: 2px solid rgba(255, 255, 255, 0.05);
    box-shadow: -8px -8px 12px rgba(255, 255, 255, 0.04), 8px 8px 12px rgba(0, 0, 0, 0.16);
}

@media screen and (min-width: 300px) and (max-width: 450px) {
    .wheel {
        width: 240px;
        height: 240px;
        margin-top: 47px;
    }
    .wheelBg {
        width: 335px;
        height: 335px;
        z-index: 1;
    }
    .wheelLogo {
        margin-top: 135px;
        margin-left: 10px;
        width: 80px;
        height: 80px;
        position: absolute;
        z-index: 3;
    }
    .marker {
        width: 60px;
        height: 70px;
    }
    .spinWheel-title-container {
        margin-top: 30px;
        margin-left: 20px;
    }
    .wheel-container {
        margin-bottom: 30px;
        margin-top: 20px;
    }
    .spinWheel-title {
        font-size: 50px;
    }
}

@media screen and (min-width: 500px) and (max-width: 1024px) {
    .wheel {
        width: 325px;
        height: 325px;
        position: absolute;
        z-index: 1;
        margin-top: 62px;
    }
}

.blur {
    animation: blur 10s;
}

@keyframes blur {
    0% {
        filter: blur(1.5px);
    }
    80% {
        filter: blur(1.5px);
    }
    100% {
        filter: blur(0px);
    }
}

.display {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 50px;
    border: 1px solid black;
    border-radius: 20px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2rem;
    margin: 20px auto;
    color: bisque;
}

.phone-num-input {
    border: none;
    width: 100px;
    background: transparent;
    outline: none;
    color: #FFFFFF;
}

.input-phone-number {
    border-bottom: 1px solid #FFFFFF;
    width: 230px;
    margin-right: 10px;
}

.spin-send-button {
    padding: 5px 10px;
    color: #FFFFFF;
    font-weight: bold;
    border: none;
    background-color: #D1964A;
    border-radius: 40px;
    border: 2px solid rgba(255, 255, 255, 0.05);
    box-shadow: -8px -8px 12px rgba(255, 255, 255, 0.04), 8px 8px 12px rgba(0, 0, 0, 0.16);
}

.pageSlider-exit>.page {
    z-index: 1;
}

.pageSlider-enter>.page {
    z-index: 10;
}

.pageSlider-enter.left>.page {
    transform: translate3d(100%, 0, 0);
}

.pageSlider-enter.right>.page {
    transform: translate3d(-100%, 0, 0);
}

.pageSlider-exit.left>.page {
    transform: translate3d(-100%, 0, 0);
}

.pageSlider-exit.right>.page {
    transform: translate3d(100%, 0, 0);
}

.pageSlider-enter.pageSlider-enter-active>.page {
    transform: translate3d(0, 0, 0);
    transition: all 800ms ease-in-out;
}


/* .popUp {
    position: absolute;
    left: 50%;
    z-index: 1;
    margin-top: 80px;
}

.popUpSubContainer {
    position: relative;
    left: -50%;
}

.wheel-container {
    z-index: 0;
} */