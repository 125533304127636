.bitcoinTitle {
    color: #FFE099;
    font-weight: bold;
    font-size: 64px;
}

.landingScreenHeadderContainer {
    margin-bottom: 0px;
}

.homeScreen-title-container {
    padding-top: 250px !important;
}

.homeScreen-title {
    z-index: 999;
}

.downloadTitleMobile {
    display: none;
}

.homebg {
    background-color: #ACACAC;
    display: flex;
    border-radius: 100%;
    margin-top: 100px;
    margin-left: 30px;
    opacity: 0.1;
    width: 400px;
    height: 366px;
    filter: blur(100px);
    position: absolute;
}

.start-rating {
    display: flex;
    align-items: center;
}

.star {
    width: 100px;
    height: auto;
}

.homeScreen-phone {
    /* margin-left: -250px; */
    margin-top: 0px;
    margin-right: -50px !important;
}

.homeScreen-phone2 {
    display: none;
}

.homeScreen-img {
    width: 649px;
    height: auto;
}

.head-title {
    background: linear-gradient(90deg, #FBCC65 0%, #FBCC65 0.01%, #FBCC65 26.04%, #E59212 80.4%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #FFFFFF;
    font-size: 64px;
    font-weight: 700;
    line-height: 120%;
    /* margin-top: 100px; */
}

.downloadLinks {
    margin-top: 150px !important;
}

.phone-num-container {
    margin-bottom: 30px;
}

.sub-title {
    color: #FFFFFF;
    font-size: 26px;
    margin: 30px 0px 0px 0px;
    font-weight: 400;
}

.phone-num-input {
    border: none;
    background-color: #212426;
    width: 100px;
    outline: none;
    color: #FFFFFF;
}

.input-phone-number {
    border-bottom: 1px solid #FFFFFF;
    width: 230px;
    margin-right: 10px;
}

.send-button {
    background-color: #D1964A;
    border: 2px solid rgba(255, 255, 255, 0.05);
    padding: 6px 20px;
    border-radius: 40px;
    box-shadow: -8px -8px 12px rgba(255, 255, 255, 0.04), 8px 8px 12px rgba(0, 0, 0, 0.16);
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
}

.send-button:focus {
    background-color: #212426;
}

.inc-image {
    width: 175px;
    height: auto;
}

.accel-image {
    width: 239px;
    height: auto;
}

.number-rating {
    color: #FFFFFF;
    opacity: 0.6;
    font-weight: 700;
    font-size: 14px;
}

.downloadTitle {
    color: var(--text-high-emphasis, #FFF);
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-top: 30px;
}

.downloadTitleLinear {
    background: linear-gradient(90deg, #FBCC65 0%, #FBCC65 0.01%, #FBCC65 26.04%, #E59212 80.4%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.googlePlayLogo {
    width: 270px !important;
    height: auto;
}

.appStoreLogo {
    width: 270px !important;
    height: auto;
}

@media screen and (min-width: 320px) and (max-width: 900px) {

    .homeScreen-title-container {
        text-align: center;
        margin-top: 0px;
        padding-top: 170px !important;
        background-image: none;
    }

    .homeScreen-title {
        padding-top: 80px;
    }

    .sectionOne {
        position: relative;
        /* margin-bottom: 80px; */
    }

    .sub-title {
        margin-top: 20px;
        font-size: 18px;
        font-weight: 500;
        padding: 0px 40px 0px 40px;
    }

    .homeScreen-phone {
        display: none;
    }

    .homeScreen-phone2 {
        display: block;
        margin-top: 40px;
        /* margin-top: -170px; */
    }

    .homeScreen-img2 {
        width: 335px;
        height: auto;
    }

    .qr-button {
        display: block;
        text-align: center;
        padding: 15px 45px;
        border-radius: 32px;
        border: 2px solid rgba(255, 255, 255, 0.05);
        background: var(--brand-primary, #D1964A);
        font-size: 18px;
        font-weight: 700;
        line-height: 150%;
        box-shadow: 8px 8px 12px 0px rgba(0, 0, 0, 0.16), -8px -8px 12px 0px rgba(255, 255, 255, 0.04);
    }

    .downloadTitle {
        display: none;
    }

    .downloadTitleMobile {
        display: block;
        color: var(--text-high-emphasis, #FFF);
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 127%;
        margin-top: 20px;
    }

    .downloadTitleLinearMobile {
        background: linear-gradient(90deg, #D7A65D 0%, #AA853D 0.01%, #F9BC70 26.04%, #FFD770 47.4%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .downloadLinks {
        margin-top: 88px !important;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .downloadTitle {
        text-align: center;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 127.7%;
    }

    /* .downloadLinksContainer {
        flex-direction: column;
        gap: 20px;
    } */

    .head-title {
        margin-top: -50px;
        font-size: 32px;
        font-weight: 700;
        padding: 0px 20px 0px 20px;
        line-height: normal;
        letter-spacing: -0.64px;
    }

    .bitcoinTitle {
        font-size: 44px;
        font-weight: 700;
    }

    .chromeWebStoreLogo {
        margin-top: 20px;
    }

    .forbes-FE-container {
        margin-top: 20px;
    }

    .inc-image {
        width: 86px;
        height: auto;
    }

    .IBS-image {
        width: 105px;
        height: 30px;
    }

    .accel-image {
        width: 107px;
        height: auto;
    }

    .homebg {
        display: none;
    }

    .webstore {
        display: none;
    }

    .downloadLinksContainer {
       display: flex !important;
        gap: 20px;
        align-items: center;
        justify-content: center;
    }

    .googlePlayLogo {
        width: 162px !important;
        height: auto;
    }

    .appStoreLogo {
        width: 162px !important;
        height: auto;
    }


    .homeScreen-title {
        z-index: 000;
    }
}

@media screen and (min-width: 768px) and (max-width: 1000px) {


    .homeScreen-title-container {
        text-align: center;
    }

    .homeScreen-phone2 {
        display: block;
        margin-top: 40px;
        /* margin-top: -150px; */
    }

    .homeScreen-img2 {
        width: 335px;
        height: auto;
    }

    .homeScreen-phone {
        display: block;
    }

    .sectionOne {
        position: relative;
        margin-bottom: 80px;
    }

    .homeScreen-img {
        display: none;
    }

    .downloadTitle {
        display: none;
    }

    .downloadTitleMobile {
        display: block;
        color: var(--text-high-emphasis, #FFF);
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 127%;
        margin-top: 25px;
    }

    .downloadTitleLinearMobile {
        background: linear-gradient(90deg, #D7A65D 0%, #AA853D 0.01%, #F9BC70 26.04%, #FFD770 47.4%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .downloadLinks {
        margin-top: 88px !important;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .downloadTitle {
        text-align: center;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 127.7%;
    }

    /* .downloadLinksContainer {
        flex-direction: column;
        gap: 20px;
        align-items: center;
    } */

    .qr-button {
        display: block;
        text-align: center;
        padding: 15px 45px;
        border-radius: 32px;
        border: 2px solid rgba(255, 255, 255, 0.05);
        background: var(--brand-primary, #D1964A);
        font-size: 18px;
        font-weight: 700;
        line-height: 150%;
        /* Card/ Neu Extrusion */
        box-shadow: 8px 8px 12px 0px rgba(0, 0, 0, 0.16), -8px -8px 12px 0px rgba(255, 255, 255, 0.04);
    }

}

@media screen and (min-width: 1009px) and (max-width: 1199px) {
    .head-title{
        font-size: 52px;
    }
}

@media screen and (min-width: 1023px) and (max-width: 1439px) {
    .homeScreen-img {
        max-width: 100% !important;
        max-height: auto;
    }

    .downloadLinksContainer {
        display: flex;
        gap: 20px;
        align-items: center;
    }
}

@media screen and (min-width: 1003px) {

    .landingScreenHeadderContainer {
        margin-bottom: 40px;
    }

    .homeScreen-phone {
        /* margin-top: -25px; */
    }

    .homeScreen-img {
        /* text-align: right; */
       max-width: 100%;
       max-height: auto;
    }

    .homeScreen-title-container {
        padding-top: 60px;
        /* justify-content: space-between; */
    }

}