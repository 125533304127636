.modalContainer {
    border-radius: 20px;
    background: #0f0f0f;
    margin: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalTitle {
    color: var(--text-high-emphasis, #FFF);
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: 46px;
    letter-spacing: -0.76px;
    text-align: left;
}

.modalSubtitle {
    color: #FFFFFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin: 85px 0px 30px 0px;
}

.inputFieldContainer {
    border-radius: 10px;
    border: 1px solid var(--text-disabled, #343739);
    background: #0F0F0F;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px 10px 10px 20px;
    margin-bottom: 30px;
}

.inputField {
    border: 1px solid var(--text-disabled, #343739);
    background: #0F0F0F;
    border: none;
    outline: none;
    color: #FFF;
    width: 156px;
}

.inputFieldDivider {
    width: 1px;
    height: 40px;
    opacity: 0.2;
    background: #FFF;
    margin: 4px 20px 4px 15px;
}

.getOTPButton {
    border-radius: 10px;
    background: var(--text-disabled, #343739);
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
    color: var(--text-medium-emphasis, #A6A7A8);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.ModalQRConatiner {
    margin: 50px 0px;
}

.ModalQRTitle {
    color: var(--text-high-emphasis, #FFF);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 15px;
}

.modalRespMessage {
    color: var(--brand-primary, #D1964A);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
}

.modalTimerConatiner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.modalTimer {
    color: var(--text-medium-emphasis, #A6A7A8);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalTimerImg {
    width: 24px;
    height: auto;
}

.otp-input {
    margin: 30px 0px 60px 0px;
}

.inputOtp {
    height: 67.5px;
    width: 69.5px !important;
    border: 1px solid var(--Text-Disabled, #343739);
    background: #0F0F0F;
    background: #212426;
    outline: none;
    border-radius: 10px;
    margin-right: 21px;
    color: #FFFFFF;
    font-size: 16px;
    -webkit-appearance: none;
}

.proceedButton {
    display: flex;
    padding: 10px 51px 10px 48px;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 10px;
    background: var(--Text-Disabled, #343739);
    color: var(--Text-Medium-Emphasis, #A6A7A8);
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 28px;
    margin-top: 30px;
}