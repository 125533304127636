.customers-title {
    color: #A6A7A8;
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 10px;
}

.customers-sub-title {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 55px;
    margin-bottom: 60px;
    text-align: center;
}

@media screen and (min-width: 300px) and (max-width: 750px) {
    .customers-title {
        font-size: 18px;
        font-weight: 500;
    }
    .customers-sub-title {
        font-weight: 700;
        font-size: 30px;
        margin-bottom: 15px;
    }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
    .customers-sub-title {
        font-weight: 700;
        font-size: 39px;
        margin-bottom: 15px;
    }
}