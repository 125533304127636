.alrdeyRegSpinRewardPopUp {
    box-shadow: -8px -8px 12px rgba(255, 255, 255, 0.04), 8px 8px 12px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    text-align: center;
    left: 0px;
    right: 0px;
    position: relative;
}

.alrdyRegpopUpSImg {
    display: flex;
    justify-content: center;
    align-self: center;
    background: #FFFFFF;
    box-shadow: -8px -8px 12px rgba(255, 255, 255, 0.04), 8px 8px 12px rgba(209, 150, 74, 0.3);
    border-radius: 100%;
    margin-top: -80px;
    width: 160px;
    height: 160px;
}

.alrdyPopImg {
    width: 120px;
    height: 120px;
    align-self: center;
}

.alrdyRegpopUpTop {
    height: 140px;
    background: linear-gradient(90deg, #F4EEE2 0%, #F6D5AC 100%);
    border-radius: 10px 10px 0px 0px;
}

.alrdyPopUptitle {
    font-weight: 700;
    font-size: 24px;
    padding: 20px 22px 0px 22px;
}

.alrdyPopUpsubTitle {
    color: #212426;
    font-weight: 600;
    font-size: 18px;
    align-self: center;
    padding: 0px 43px;
}

.alrdeyRegSpinRewardPopUp .modal-dialog {
    max-width: 420px;
}