.header {
    /* background-color: #212426; */
    /* overflow: hidden; */
    display: flex;
    margin-top: 25px;
    align-items: center;
    justify-content: space-between !important;
    position: fixed;
    /* margin-left: 50px !important; */
    /* top: 0px; */
}

.header-container {
    padding-top: 26px;
    padding-bottom: 13px;
    position: fixed;
    width: 100%;
    background-color: #0F0F0F;
    z-index: 1000;
}

.slider ul {
    width: 100%;
    transition: 1s ease;
    display: flex;
    /* justify-content: space-between; */
}

.slider ul li:nth-child(-n+7) {
    /* margin-block: .5rem; */
    /* margin-inline: 2rem; */
    list-style: none;
    margin-right: 30px;
}

.footer-links-nav {
    display: none;
}

.slider ul li a {
    font-size: 16px;
    text-decoration: none;
    color: #FFFFFF;
}

.slider.active {
    top: 0;
    transition: 1s ease;
}

.menu-icon .menu {
    display: none;
}

.closed {
    display: none;

}

.closed .close {
    font-size: 30px;
}

.brand-logo-web {
    display: block;
    width: 125px !important;
    height: auto;
}

.brand-logo {
    display: none;
}

.divNav {
    display: flex;
    align-items: center;
}

.qr-button {
    display: block;
    border: 2px solid rgba(255, 255, 255, 0.05);
    position: relative;
    padding: 5px 15px;
    border-radius: 20px;
    box-shadow: -8px -8px 12px rgba(80, 77, 77, 0.04), 8px 8px 12px rgba(0, 0, 0, 0.16);
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    background-color: #D1964A;
}

.qr-container {
    background-color: #212426;
    display: none;
    transition: opacity .5s, top .5s;
    border: 2px solid rgba(255, 255, 255, 0.05);
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: -8px -8px 12px rgba(255, 255, 255, 0.04), 8px 8px 12px rgba(0, 0, 0, 0.16);
    color: #ffffff;
    font-weight: bold;
    position: absolute;
    text-align: center;
    margin-left: 15px;
    z-index: 1;
}

.qr-button:hover+.qr-container,
.qr-container:hover {
    display: block;
    transition: opacity .5s, top .5s;
}

.qr-button1 {
    display: none;
}

.hamburgerSocialMedia {
    display: none;
}

.marqueeNavBar {
    background: linear-gradient(83deg, #FFFFFF 16%, #ECDFFF 42%);
    padding: 18.5px 0px;
    margin-top: 20px;
    display: block;
}

.marqueeNavBarMob {
    display: none;
}

.marqueeContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.leftLayout {
    background: linear-gradient(90deg, #EEE2FF 1%, rgba(238, 226, 255, 0.00) 100%);
    height: 40px;
    width: 50px !important;
    position: absolute;
    margin-top: 0px;
    z-index: 888;
    left: 0px;
}

.timerFont {
    font-size: 18px;
}

.campTimerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background: #2E1A56;
    padding: 9px 12px;
    border-radius: 100px;
}

.timerIcon {
    width: 16px;
    height: auto;
}

.timeCounter {
    color: #FFFFFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 128.571% */
    margin-top: 2px;
}

.navSocialmedia {
    display: none;
}

.qrdivider {
    border: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 15px;
    margin-bottom: 10px;
}

.navBarDivider {
    display: none;
}

.qr-button2 {
    display: none;
}

.careerHiring {
    background-color: #6B39B2;
    padding: 1px 2px 0.5px;
    border-radius: 4px;
    color: #FFFFFF;
    margin-left: 8px;
}

.qrdivider {
    border: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 15px;
    margin-bottom: 10px;
}

.qrfa {
    color: #FFFFFF;
}

.qrExcentation {
    border: none;
    /* background: linear-gradient(90deg, #F4EEE2 0%, #F6D5AC 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text; */
    color: #FFFFFF;
    background: none;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    /* align-items: center; */
}

.qrArrow {
    background: #D1964A;
    border: 0.727273px solid rgba(0, 0, 0, 0.08);
    box-shadow: -5.81818px -5.81818px 8.72727px rgba(255, 255, 255, 0.04), 5.81818px 5.81818px 8.72727px rgba(0, 0, 0, 0.16);
    border-radius: 72.7273px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    margin-left: 12px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-links-about:hover {
    color: #FFE099 !important;
    text-decoration: none;
}

.footer-links-about::active {
    color: #FFE099 !important;
    text-decoration: none;
}

.footer-links-careers:hover {
    color: #FFE099 !important;
    text-decoration: none;
}

.footer-links-careers {
    color: #FFFFFF !important;
    text-decoration: none;
}

.footer-links-about {
    color: #FFFFFF !important;
    text-decoration: none;
}

.footer-links-card:hover {
    color: #FFE099 !important;
    text-decoration: none;
}

/* .footer-links-card::active {
    color: #FFE099 !important;
    text-decoration: none;
} */

.active-link {
    color: #FFE099 !important;
    text-decoration: none;
}

.footer-links-card {
    color: #FFFFFF !important;
    text-decoration: none;
    /* margin-right: -30px; */
}

.qr-image {
    width: 32px;
    height: 32px;
    margin-right: 10px;
}

.qr-container img {
    width: 112px;
    height: auto;
}

.qrExcentation:hover {
    cursor: pointer;
}


@media screen and (max-width: 940px) {
    .header {
        /* background-color: #212426; */
        overflow: hidden;
        display: block;
    }

    .footer-links-nav {
        display: block;
    }

    .brand-logo {
        display: block;
        width: 111px !important;
        height: auto;
        padding-top: 13px;
    }

    .divNav {
        display: inline-block;
        margin-top: -65px !important;
        margin-left: 20px;
        width: 100%;
    }

    .brand-logo-web {
        display: block;
        width: 111px;
    }

    .slider {
        position: fixed;
        min-width: 100%;
        z-index: 999;
        /* height: 100vh; */
        top: -100%;
        background-color: #212426;
        transition: 1s ease;
        margin-left: -20px;
    }

    .slider ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 0px;
    }

    .slider ul li {
        margin-block: 25px;
        list-style: none;
        font-size: 18px;
    }

    .slider.active {
        top: 0;
        transition: 1s ease;
        margin-left: -20px;
    }

    .menu-icon .menu {
        display: block;
        color: #FFFFFF;
        font-size: 30px;
        cursor: pointer;
    }

    .menu-icon {
        width: 100%;
        justify-content: space-between !important;
        align-items: center !important;
        display: flex !important;
        padding-bottom: 20px;
    }

    .header-container {
        margin-top: -20px !important;
    }

    .closed {
        display: block;
        cursor: pointer;
        color: #FFFFFF;
        font-size: 30px;
        justify-content: flex-end;
        padding-right: 20px !important;
        display: flex;
        width: 100%;
        margin-top: 20px;
        z-index: 5;
    }

    .closed .close {
        font-size: 30px;
    }

    .qr-button:hover+.qr-container {
        display: none;
    }

    .qr-image {
        display: none;
    }

    .qr-button1 {
        display: none;
        border: 2px solid rgba(255, 255, 255, 0.05);
        position: relative;
        padding: 5px 15px;
        border-radius: 20px;
        box-shadow: -8px -8px 12px rgba(80, 77, 77, 0.04), 8px 8px 12px rgba(0, 0, 0, 0.16);
        color: #FFFFFF;
        font-size: 16px;
        font-weight: bold;
        background-color: #D1964A;
    }

    .qr-container {
        /* background-color: #212426; */
        display: none;

    }

    .qr-button {
        display: none;
    }

    .hamburgerSocialMedia {
        display: block;
        padding-top: 25px !important;
    }

    .nav-Social-media {
        border: 1.5px solid #2E3133;
        border-radius: 100%;
        padding: 9.8px 10px;
        display: flex;
        justify-content: center !important;
        align-items: center;
        margin-right: 15px;
    }

    .navSocialmedia {
        display: block;
        justify-content: center;
    }

    .navBarDivider {
        display: block;
        border: 1px solid rgba(255, 255, 255, 0.1);
        margin-left: -23px;
    }

    .timerFont {
        font-size: 14px;
    }

    .marqueeNavBar {
        display: none;
    }

    .marqueeNavBarMob {
        background: linear-gradient(83deg, #FFFFFF 16%, #ECDFFF 42%);
        padding: 10px 10px 10px 0px !important;
        margin-top: 20px;
        display: block;
    }

    .timeCounter {
        color: #FFFFFF;
    }

    .campTimerContainer {
        padding: 4px !important;
    }
}