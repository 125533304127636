@font-face {
    font-family: 'HK Grotesk';
    src: local('HK Grotesk'), url('./fonts/HKGrotesk-Regular.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'HK Grotesk';
    src: local('Hk Grotesk'), url('./fonts/HKGrotesk-Regular.ttf') format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: 'HK Grotesk';
    src: local('HK Grotesk'), url('./fonts/HKGrotesk-Medium.ttf') format('truetype');
    font-weight: 300; /* Medium */
}

@font-face {
    font-family: 'HK Grotesk';
    src: local('Hk Grotesk'), url('./fonts/HKGrotesk-SemiBold.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'HK Grotesk';
    src: local('HK Grotesk'), url('./fonts/HKGrotesk-Bold.ttf') format('truetype');
    font-weight: 700;
}

.App {
    background-color: #0F0F0F !important;
    font-family: 'HK Grotesk';
    overflow: auto;
}

/* ::-webkit-scrollbar {
    display: none;
}
 */

::-webkit-scrollbar {
    width: 0px;
    border: 1px solid #fff;
}

::-webkit-scrollbar-track {
    border-radius: 0;
    background: #eeeeee;
}

::-webkit-scrollbar-thumb {
    border-radius: 0;
    background: #b0b0b0;
}

.content-wrapper {
    /* display: none; */
    align-items: center;
    margin-bottom: 212px;
    width: 100%;
}

.content-wrapperTow {
    display: none;
}

@media screen and (min-width: 300px) and (max-width: 750px) {
    .container {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .content-wrapper {
        display: none;
        align-items: center;
        /* margin-bottom: 350px; */
        width: 100%;
        /* overflow-x: auto;
        overflow-y: hidden;
        scroll-behavior: smooth; */
    }

    .content-wrapperTow {
        display: flex;
        align-items: center;
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        scroll-behavior: smooth;
    }

    .content-wrapperTow::-webkit-scrollbar {
        display: block;
        border: none;
        height: 2px;
    }

    .content-wrapperTow::-webkit-scrollbar-track {
        background: transparent;
        background-color: #343739;
        border-radius: 100px;
        margin-right: 120px;
        margin-left: 120px;
    }

    .content-wrapperTow::-webkit-scrollbar-thumb {
        background-color: #FFFFFF;
        border-radius: 100px;
        position: absolute;
        width: 40px;
    }


    .App::-webkit-scrollbar {
        display: none;
    }

    /* .content-wrapperTow::-webkit-scrollbar-track-piece:end {
       
        margin-right: 40px !important; 
    }
     */
    /*  .content-wrapperTow::-webkit-scrollbar-track-piece:start {
        
        margin-left: 40px !important;
    } */

    .marquee-section {
        display: none !important;
    }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
    .content-wrapper {
        align-items: center;
        width: 100%;
        margin-bottom: 0px;
    }
}