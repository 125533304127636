.twitte-container {
    margin-right: 10px;
}

.twitteImg {
    width: auto;
    height: 259px;
    /* border-radius: 10px; */
    /* overflow: hidden; */
}

.twitteImgMob{
    display: none;
}

@media screen and (min-width: 320px) and (max-width: 450px) {
    .twitteImgMob{
        display: block;
        width: 320px;
        height: auto;
    }
    .twitteImg {
        display: none;
    }
    .twitte-container{
        margin-bottom: 14px;
    }
}
